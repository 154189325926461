.button {
  --button-bg-color: transparent;
  --button-bg-color-hover: transparent;
  --button-gap: #{rem(15)};
  --button-icon-color: var(--accent-secondary-default);
  --button-icon-color-hover: var(--accent-secondary-hover);
  --button-min-height: #{rem(64)};
  --button-padding: #{rem(35)};
  --button-text-color: var(--accent-secondary-default);
  --button-text-color-hover: var(--accent-secondary-hover);

  position: relative;
  margin: 0;

  padding: 0;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--button-text-color);
  font: var(--font-btn);
  text-decoration: none;

  background: var(--button-bg-color);
  border: none;
  border-radius: var(--radius-infinity);
  overflow: hidden;

  transition: color var(--animation-default),
    background-color var(--animation-default),
    border-color var(--animation-default);

  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @include mediaBigDesktop {
    --button-gap: #{big(15)};
    --button-min-height: #{big(64)};
    --button-padding: #{big(35)};
  }

  @include mediaDesktop {
    --button-gap: #{rem(10)};
    --button-min-height: #{rem(54)};
    --button-padding: #{rem(30)};
  }

  @include mediaLaptop {
    --button-gap: #{rem(8)};
    --button-min-height: #{rem(44)};
    --button-padding: #{rem(20)};
  }

  @include hover {
    color: var(--button-text-color-hover);

    &:not([data-button-animation]) {
      background-color: var(--button-bg-color-hover);

      & .icon {
        fill: var(--button-icon-color-hover);
      }
    }
  }

  &[data-button-flip] {
    @include hover {
      & .button {
        &__text {
          & > span[data-button-text] {
            transform: translateY(-100%);
          }
        }
      }
    }

    & .button {
      &__text {
        & > span[data-button-text] {
          position: relative;

          display: block;

          transition: transform var(--animation-default);

          &::before {
            content: attr(data-button-text);

            position: absolute;
            top: 100%;
            left: 0;
          }
        }
      }
    }
  }

  &:disabled,
  &.disabled {
    color: var(--text-disabled);

    opacity: 1;

    pointer-events: none;

    & .icon {
      fill: var(--text-disabled);
    }
  }

  & .icon {
    order: 0;

    fill: var(--button-icon-color);

    transition: fill var(--animation-default);
  }

  &__content {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--button-gap);

    pointer-events: none;
  }

  &__text {
    order: 1;

    width: fit-content;
    height: fit-content;
    display: block;

    white-space: nowrap;
  }

  &_icon {
    &_right {
      & .icon {
        order: 2;
      }
    }
  }

  &[data-button-animation] {
    @media (any-hover: hover) {
      --before-left: 0;
      --before-scale: 0;
      --before-size: 0;
      --before-top: 0;

      &::before {
        content: "";

        position: absolute;
        top: var(--before-top);
        left: var(--before-left);
        z-index: 0;

        width: var(--before-size);
        height: var(--before-size);

        background-color: var(--button-bg-color-hover);
        border-radius: var(--radius-infinity);

        transform: translate(-50%, -50%);
        transition: opacity var(--animation-default),
          background-color var(--animation-default);

        pointer-events: none;
        user-select: none;
      }
    }
  }
}

@import "button/primary",
"button/secondary",
"button/tertiary",
"button/icon"
