.article blockquote,
.blockquote {
  margin: 0;

  padding: rem(40) rem(50);
  width: 100%;
  display: block;

  color: var(--text-dark-primary);
  font: var(--font-body-M-r);

  background-color: #FEF4E2;
  border-radius: var(--radius-common);

  @include mediaBigDesktop {
    padding: big(40) big(50);
  }

  @include mediaLaptop {
    padding: rem(30) rem(40);

    border-radius: var(--radius-secondary);
  }

  @include mediaTablet {
    padding: rem(30);
  }

  @include mediaMobile {
    padding: rem(20) rem(15);
  }

  &::before {
    content: "";

    margin-bottom: rem(30);

    width: rem(28);
    height: rem(28);
    display: flex;

    background-color: var(--accent-secondary-default);

    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cpath d='M27.0455 0.234724L26.4091 4.88018C24.7545 4.75291 23.5455 5.0923 22.7818 5.89836C22.0182 6.70442 21.5515 7.78624 21.3818 9.14381C21.2121 10.5014 21.1697 11.965 21.2545 13.5347H27.0455V26.962H16.1636V10.9893C16.1636 7.08624 17.1394 4.15897 19.0909 2.20745C21.0849 0.255937 23.7364 -0.401639 27.0455 0.234724ZM10.8818 0.234724L10.2455 4.88018C8.59091 4.75291 7.38182 5.0923 6.61818 5.89836C5.85455 6.70442 5.38788 7.78624 5.21818 9.14381C5.04848 10.5014 5.00606 11.965 5.09091 13.5347H10.8818V26.962H0V10.9893C0 7.08624 0.975758 4.15897 2.92727 2.20745C4.92121 0.255937 7.57273 -0.401639 10.8818 0.234724Z' fill='black'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: contain;

    @include mediaBigDesktop {
      margin-bottom: big(30);

      width: big(28);
      height: big(28);
    }

    @include mediaDesktop {
      width: rem(22);
      height: rem(22);
    }

    @include mediaLaptop {
      margin-bottom: rem(25);
    }

    @include mediaTablet {
      margin-bottom: rem(20);
    }
  }
}

.article blockquote,
.article .blockquote {
  margin: rem(50) 0;

  @include mediaBigDesktop {
    margin: big(50) 0;
  }

  @include mediaDesktop {
    margin: rem(40) 0;
  }

  @include mediaLaptop {
    margin: rem(30) 0;
  }

  @include mediaTablet {
    margin: rem(25) 0;
  }
}

blockquote .blockquote-footer,
.blockquote .blockquote-footer {
  margin-top: rem(30);

  padding-top: rem(29);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: rem(20);

  border-top: solid 1px var(--stroke-light);

  @include mediaBigDesktop {
    margin-top: big(30);

    padding-top: big(29);
    gap: big(20);

    border-top-width: big(1);
  }

  @include mediaLaptop {
    margin-top: rem(25);

    padding-top: rem(25);
  }

  @include mediaTablet {
    margin-top: rem(20);

    padding-top: rem(20);
  }

  @include mediaMobile {
    padding-top: rem(15);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);
  }
}

blockquote .blockquote-author,
.blockquote .blockquote-author {
  width: 100%;
  display: block;

  color: var(--text-dark-primary);
  font: var(--font-body-L-m) !important;

  & span {
    color: var(--text-dark-secondary);
    font: var(--font-body-L-r) !important;
  }
}

blockquote .blockquote-button,
.blockquote .blockquote-button {
  width: fit-content;
  flex-shrink: 0;

  @include mediaMobile {
    width: 100%;
    flex-shrink: unset;
  }
}
