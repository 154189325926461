.tab-list {
  margin-bottom: rem(30);

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: rem(20);

  @include mediaBigDesktop {
    margin-bottom: big(30);

    gap: big(20);
  }

  @include mediaDesktop {
    gap: rem(10);
  }

  @include mediaTablet {
    position: relative;
    right: var(--container-offset);
    margin-bottom: rem(20);

    padding: 0 var(--container-offset);
    width: calc(100% + (var(--container-offset) * 2));
    flex-wrap: nowrap;

    overflow-x: auto;
    overflow-y: hidden;

    @include scrollbarDelete;

    @media (any-hover: hover) {
      flex-wrap: wrap;
    }
  }

  @include mediaMobile {
    gap: rem(10);
  }
  
  &_margin {
    &_bottom {
      &_zero {
        margin-bottom: 0;
      }
    }
  }

  & .tab {
    --tab-bg-color: var(--bg-extralight);
    --tab-bg-color-active: #FFE474;
    --tab-bg-color-hover: #FFE474;
    --tab-color-active: var(--text-dark-primary);
    --tab-color-hover: var(--text-dark-primary);

    position: relative;

    display: flex;

    text-decoration: none;

    cursor: pointer;
    user-select: none;

    @include hover {
      & .tab__item {
        background-color: var(--tab-bg-color-hover);
        color: var(--tab-color-hover);
      }
    }

    &::before {
      @include pseudo(10, false, true);
    }
    
    &_active {
      & .tab__item {
        background-color: var(--tab-bg-color-hover);
        color: var(--tab-color-hover);
      }
    }
    
    &_primary {
      --tab-bg-color-active: var(--accent-primary-default);
      --tab-bg-color-hover: var(--accent-primary-default);
      --tab-color-hover: var(--text-light-primary);
      //--tab-color-active: var(--text-light-primary);
    }

    &__item {
      padding: rem(10) rem(20);

      color: var(--tab-color-active);
      font: var(--font-body-L-m);
      white-space: nowrap;

      background-color: var(--tab-bg-color);
      border-radius: calc(var(--radius-common) + #{rem(5)});

      transition: color var(--animation-default),
      background-color var(--animation-default);

      @include mediaBigDesktop {
        padding: big(10) big(20);

        border-radius: calc(var(--radius-common) + #{big(5)});
      }

      @include mediaTablet {
        padding: rem(10) rem(15);
      }
    }
  }
}
