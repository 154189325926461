.fancybox {
  &__container {

    /* Стандартные модальные окна */
    &:not(.has-toolbar) {
      & .fancybox {
        &__backdrop {
          background-color: rgba(0, 0, 0, 0.35);
        }

        &__slide {
          padding: 0 var(--container-offset) !important;
          height: 100%;

          overflow: hidden;

          &::before,
          &::after {
            content: unset;
          }
        }
      }
    }

    /* Фото */
    &.has-toolbar {
      & .fancybox {
        &__backdrop {
          --fancybox-bg: rgba(0, 0, 0, 0.7);
        }

        &__toolbar {
          top: rem(50);

          @include mediaBigDesktop {
            top: big(50);
          }

          @include mediaLaptop {
            top: rem(40);
          }

          @include media(#{rem(1000)}) {
            top: rem(20);
          }

          & .is-right {
            margin-right: rem(50);

            @include mediaBigDesktop {
              margin-right: rem(50);
            }

            @include mediaLaptop {
              margin-right: rem(40);
            }

            @include media(#{rem(1000)}) {
              margin-right: rem(20);
            }

            & .f-button {
              width: rem(24);
              height: rem(24);

              @include mediaBigDesktop {
                width: big(24);
                height: big(24);
              }

              @include mediaTablet {
                width: rem(20);
                height: rem(20);
              }

              @include mediaMobile {
                width: rem(18);
                height: rem(18);
              }

              &[data-fancybox-close] {
                background: transparent;

                @include hover {
                  &::after {
                    background-color: var(--accent-primary-default);
                  }
                }

                & svg {
                  display: none;
                }

                &::before {
                  @include pseudo(10, false, true);
                }

                &::after {
                  content: "";

                  width: 100%;
                  height: 100%;

                  background-color: var(--text-light-primary);

                  transition: background-color var(--animation-default);

                  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4144 10.0002L16.4144 5.00015L15.0002 3.58594L10.0002 8.58594L5.00015 3.58594L3.58594 5.00015L8.58594 10.0002L3.58594 15.0002L5.00015 16.4144L10.0002 11.4144L15.0002 16.4144L16.4144 15.0002L11.4144 10.0002Z' fill='white'/%3E%3C/svg%3E");
                  mask-position: center;
                  mask-repeat: no-repeat;
                  mask-size: contain;
                }
              }
            }
          }
        }
      }
    }

    & .fancybox {
      &__slide {
        padding: rem(50) rem(20);

        @include mediaBigDesktop {
          padding: big(50) big(20);
        }

        @include mediaMobile {
          padding: 0 !important;
        }

        &.has-image {
          @include mediaMobile {
            padding: 0 var(--container-offset) !important;
          }

          @include media(#{rem(1000)}) {
            padding: 0 var(--container-offset) !important;
          }

          & .fancybox {
            &__content {
              max-width: rem(1200);

              @include mediaBigDesktop {
                max-width: big(1200);
              }

              @include mediaDesktop {
                max-width: rem(1000);
              }

              @include mediaLaptop {
                max-width: rem(780);
              }
            }
          }

          & img {
            border-radius: var(--radius-common);

            user-select: none;

            @include mediaTablet {
              border-radius: rem(20);
            }
          }
        }
      }

      &__nav {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;

        padding: 0 calc(var(--container-offset) + #{rem(60)});
        width: 100%;
        max-width: calc(var(--container-width));
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        pointer-events: none;

        @include mediaBigDesktop {
          padding: 0 calc(var(--container-offset) + #{big(60)});
        }

        @include mediaLaptop {
          padding: 0 calc(var(--container-offset) + #{rem(80)});
        }

        @include mediaTablet {
          padding: 0 calc(var(--container-offset) + #{rem(20)});
        }

        @include mediaMobile {
          padding: 0 calc(var(--container-offset) + #{rem(10)});
        }
      }
    }

    & .f-button {

      &.is-next,
      &.is-prev {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        margin: auto !important;

        width: rem(64);
        height: rem(64);
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--text-dark-primary);

        background-color: var(--bg-white);
        border: solid 1px var(--stroke-light);
        border-radius: var(--radius-infinity);

        transform: translateY(0) !important;
        transition: color var(--animation-default);

        pointer-events: all;

        @include mediaBigDesktop {
          width: big(64);
          height: big(64);
        }

        @include mediaDesktop {
          width: rem(54);
          height: rem(54);
        }

        @include mediaLaptop {
          width: rem(44);
          height: rem(44);
        }

        @include hover {
          color: var(--accent-primary-default);

          &::after {
            background-color: var(--accent-primary-default);
          }
        }

        &:disabled {
          fill: var(--text-disabled);
          background-color: transparent;
          border-color: transparent;

          &:after {
            opacity: 0;
          }
        }

        & svg {
          display: none;
        }

        &::before {
          @include pseudo(6, false, true);
        }

        &::after {
          content: "";

          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;

          width: rem(24);
          height: rem(24);

          background-color: var(--text-dark-primary);

          transition: background-color var(--animation-default);

          mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M9.5 5.4999H4.5L6.145 3.85491C6.19186 3.80843 6.22906 3.75313 6.25445 3.6922C6.27983 3.63127 6.2929 3.56591 6.2929 3.49991C6.2929 3.4339 6.27983 3.36855 6.25445 3.30762C6.22906 3.24669 6.19186 3.19139 6.145 3.14491C6.05132 3.05178 5.92459 2.99951 5.7925 2.99951C5.66041 2.99951 5.53368 3.05178 5.44 3.14491L3.295 5.29491C3.10721 5.48158 3.00112 5.73512 3 5.9999V5.9999C3.00243 6.26296 3.10842 6.51445 3.295 6.6999L5.44 8.8499C5.48662 8.89619 5.5419 8.93285 5.60268 8.95777C5.66347 8.9827 5.72857 8.99541 5.79427 8.99518C5.85997 8.99495 5.92497 8.98178 5.98558 8.95642C6.04619 8.93107 6.10121 8.89402 6.1475 8.8474C6.19379 8.80078 6.23045 8.7455 6.25537 8.68472C6.2803 8.62393 6.29301 8.55883 6.29278 8.49313C6.29255 8.42744 6.27938 8.36243 6.25402 8.30182C6.22866 8.24121 6.19162 8.18619 6.145 8.1399L4.5 6.4999H9.5C9.63261 6.4999 9.75979 6.44723 9.85355 6.35346C9.94732 6.25969 10 6.13251 10 5.9999C10 5.8673 9.94732 5.74012 9.85355 5.64635C9.75979 5.55258 9.63261 5.4999 9.5 5.4999Z' fill='%23181818'/%3E%3C/svg%3E");
          mask-position: center;
          mask-repeat: no-repeat;
          mask-size: contain;

          @include mediaBigDesktop {
            width: big(24);
            height: big(24);
          }

          @include mediaDesktop {
            width: rem(20);
            height: rem(20);
          }

          @include mediaLaptop {
            width: rem(18);
            height: rem(18);
          }
        }
      }

      &.is-next {
        margin-right: 0 !important;

        transform: scale(-1, 1) !important;
      }

      &.is-prev {
        margin-left: 0 !important;
      }
    }
  }

  /* Стандартные модальные окна */
  &__content {
    margin: auto;

    & .f-button {
      &.is-close-btn {
        top: rem(20) !important;
        right: rem(20) !important;

        width: rem(24);
        height: rem(24);

        background: none;
        opacity: 1;

        @include mediaBigDesktop {
          top: big(20) !important;
          right: big(20) !important;

          width: big(110);
          height: big(110);
        }

        @include mediaTablet {
          top: rem(15) !important;
          right: rem(15) !important;

          width: rem(20);
          height: rem(20);
        }

        @include mediaMobile {
          width: rem(18);
          height: rem(18);
        }

        @include hover {

          &::before,
          &::after {
            background-color: var(--accent-primary-default);

            transform: rotate(0deg);
          }
        }

        & svg {
          display: none;
        }

        &::before,
        &::after {
          content: "";

          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;

          width: 86%;
          height: rem(2);

          background-color: var(--text-dark-tertiary);
          border-radius: rem(2);

          transition: background-color var(--animation-default),
            transform var(--animation-default);

          @include mediaBigDesktop {
            height: big(2);

            border-radius: big(2);
          }

          @include mediaMobile {
            height: rem(1.5);
          }
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__backdrop {
    --fancybox-bg: rgba(0, 0, 0, 0.10);
  }
}
