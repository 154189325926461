.modal-window {
  margin: auto;

  width: 100%;
  max-width: rem(622);
  padding: 0;
  width: 100%;

  background-color: var(--bg-white);
  border-radius: var(--radius-common);

  @include mediaBigDesktop {
    max-width: big(622);
  }

  @include mediaDesktop {
    max-width: rem(487);
  }

  @include mediaTablet {
    max-width: rem(451);

    border-radius: rem(20);
  }

  @include mediaMobile {
    max-width: unset;
  }
  
  &_solution {
    max-width: rem(1170);
    
    @include mediaBigDesktop {
      max-width: big(1170);
    }
    
    @include mediaDesktop {
      max-width: rem(944);
    }
    
    @include mediaLaptop {
      max-width: rem(850);
    }
    
    @include mediaTablet {
      max-width: rem(688);
    }
    
    @include mediaMobile {
      max-width: unset;
    }
    
    & .modal-window {
      &__container {
        flex-direction: row;
        gap: rem(50);
        
        @include mediaBigDesktop {
          gap: big(50);
        }
        
        @include mediaTablet {
          gap: rem(30);
        }
        
        @include mediaMobile {
          flex-direction: column;
          gap: rem(20);
        }
      }
    }
  }
  
  & .modal-form {
    &__send {
      width: fit-content;
    }
  }
  
  &__image {
    position: relative;
    
    padding: rem(40);
    gap: 0;
    height: 100%;
      
    border-radius: var(--radius-secondary);
    
    object-fit: cover;
    overflow: hidden;
    
    @include mediaBigDesktop {
      padding: big(40);
    }
    
    @include mediaLaptop {
      padding: rem(30);
    }
    
    @include mediaTablet {
      padding: rem(25);
    }
    
    @include mediaMobile {
      min-height: rem(280);
      padding: rem(15);
    }
    
    & img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      
      height: 100%;
    }
    
    & figcaption {
      z-index: 2;
      
      font: var(--font-h4);
      color: var(--text-light-primary);
    }
  }

  &__container {
    padding: rem(50);
    width: 100%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(35);

    overflow: auto;

    @include scrollbarDelete;

    @include mediaBigDesktop {
      padding: big(50);
      gap: big(35);
    }

    @include mediaDesktop {
      padding: rem(40);
      padding-bottom: rem(50);
      gap: rem(30);
    }

    @include mediaTablet {
      padding: rem(30);
      padding-bottom: rem(40);
      gap: rem(25);
    }

    @include mediaMobile {
      padding: rem(15);
      padding-top: rem(30);
      gap: rem(15);
    }
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(30);
    
    @include mediaBigDesktop {
      gap: big(30);
    }
    
    @include mediaTablet {
      gap: rem(20);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaDesktop {
      gap: rem(10);
    }

    @include mediaMobile {
      gap: rem(5);
    }
  }
  
  &__info {
    display: flex;
    flex-direction: column;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaDesktop {
      gap: rem(10);
    }

    @include mediaMobile {
      gap: rem(5);
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-h3);
  }

  &__description {
    width: 100%;
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);
  }

  & .review-card {
    padding: 0;
    padding-bottom: rem(35);
    flex-direction: column;

    border: none;
    border-bottom: solid 1px var(--stroke-light);
    border-radius: 0;

    @include mediaBigDesktop {
      padding-bottom: big(35);
    }

    @include mediaDesktop {
      padding-bottom: rem(30);
    }

    @include mediaTablet {
      padding-bottom: rem(25);
    }

    @include mediaMobile {
      padding-bottom: rem(15);
    }

    @include hover {
      box-shadow: none;
    }

    &__avatar {
      display: none;
    }

    &__text {
      -webkit-line-clamp: unset;
    }

    &__more {
      display: none;
    }
  }
}

.modal-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(25);

  @include mediaBigDesktop {
    gap: big(25);
  }

  @include mediaTablet {
    gap: rem(20);
  }

  @include mediaMobile {
    gap: rem(15);
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaDesktop {
      gap: rem(15);
    }

    @include mediaTablet {
      gap: rem(10);
    }

    & .text-input {
      width: 100%;

      & textarea.text-input__input {
        height: rem(100);

        @include mediaBigDesktop {
          height: big(100);
        }
      }
    }
  }

  &__submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include mediaTablet {
      gap: rem(25);
    }

    @include mediaMobile {
      gap: rem(15);
    }
  }

  &__send {
    width: 100%;
  }

  & .checkbox {
    --checkbox-size: #{rem(30)};
    --checkbox-gap: #{rem(15)};

    align-items: center;

    @include mediaBigDesktop {
      --checkbox-size: #{big(30)};
      --checkbox-gap: #{big(15)};
    }

    @include mediaDesktop {
      --checkbox-size: #{rem(24)};
    }

    &__input {
      &:checked {
        & ~ .checkbox__item {
          background: var(--bg-white);

          border-color: var(--stroke-light);

          &::before {
            background: var(--accent-secondary-default);
          }
        }
      }
    }

    &__item {
      --accent-primary-default: var(--accent-secondary-default);

      border-width: 1px;

      &::before {
        transition: background-color var(--animation-default);
      }
    }

    & .text {
      font: 400 rem(16)/1.55 var(--font-primary);

      @include mediaBigDesktop {
        font: 400 big(16)/1.55 var(--font-primary);
      }

      @include mediaDesktop {
        // font: 500 rem(12)/1.55 var(--font-primary);
        font: 500 rem(14)/1.55 var(--font-primary);
      }

      & a {
        color: var(--text-dark-primary);
        text-decoration: underline;

        @include hover {
          color: var(--accent-secondary-default);
        }
      }
    }
  }

  & .select-input {

    & .select-answer {
      height: 0;
    }

    & .select-input__checkbox:focus ~ .select-answer,
    & .select-input__checkbox:checked ~ .select-answer {
      height: auto !important;
    }
  }

  &-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaTablet {
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(10);
    }

    &__title {
      width: 100%;
      display: block;

      color: var(--text-dark-primary);
      font: var(--font-body-M-m);
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(10);

      @include mediaBigDesktop {
        gap: big(10);
      }

      @include mediaMobile {
        gap: rem(5);
      }

      & .button {
        flex-grow: 0;
        flex-shrink: 0;
      }

      & .modal-form-tabs {
        // &__phone {
        //   --button-bg-color: var(--accent-primary-default);
        //   --button-bg-color-hover: var(--accent-primary-hover);

        //   width: 100%;
        //   flex-shrink: 1;
        //   flex-grow: 1;
        // }

        // &__whatsapp {
        //   --button-icon-color: #60D669;
        //   --button-icon-color-hover: var(--text-light-primary);
        //   --button-bg-color-hover: var(--button-icon-color);
        // }

        // &__tg {
        //   --button-icon-color: #25A0E5;
        //   --button-icon-color-hover: var(--text-light-primary);
        //   --button-bg-color-hover: var(--button-icon-color);
        // }
      }
    }
  }
}
