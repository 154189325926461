.section {
  width: 100%;

  overflow: hidden;

  & > .container {
    display: flex;
    flex-direction: column;
    gap: var(--section-gap);
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: var(--grid-column-gap);

    @include mediaTablet {
      flex-direction: column;
      align-items: flex-start;
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 50%;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include mediaLaptop {
      gap: rem(20);
    }

    @include mediaTablet {
      flex-basis: unset;
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__name {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
  }

  &__description {
    width: 100%;
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);
  }

  &__navigation {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }
  }

  &__swiper-nav {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaTablet {
      display: none;
    }
  }

  &__content {
    width: 100%;
  }

  &_padding {
    &_top {
      padding-top: var(--section-padding);
    }

    &_bottom {
      padding-bottom: var(--section-padding);
      
      &_small {
        padding-bottom: var(--section-gap);
      }
    }
  }

  &_grid {
    --section-grid-columns: repeat(4, 1fr);

    & .container {
      display: grid;
      grid-template-columns: var(--section-grid-columns);
      row-gap: var(--grid-row-gap);
      column-gap: var(--grid-column-gap);

      @include mediaTablet {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: var(--section-gap);
      }
    }

    & .section {
      &__head {
        grid-column: 1/3;
        grid-row: 1/2;

        padding-right: rem(90);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: rem(45);

        @include mediaBigDesktop {
          padding-right: big(90);
          gap: big(45);
        }

        @include mediaDesktop {
          padding-right: rem(70);
        }

        @include mediaLaptop {
          padding-right: rem(50);
        }

        @include mediaTablet {
          padding-right: 0;
          gap: rem(15);
        }

        @include mediaMobile {
          gap: rem(10);
        }
      }

      &__content {
        grid-column: 1/5;
        grid-row: 1/2;

        pointer-events: none;

        @include mediaTablet {
          pointer-events: unset;
        }
      }

      &__title {
        flex-basis: unset;
        gap: rem(30);

        @include mediaBigDesktop {
          gap: big(30);
        }

        @include mediaLaptop {
          gap: rem(20);
        }

        @include mediaTablet {
          gap: rem(15);
        }

        @include mediaMobile {
          gap: rem(10);
        }
      }
    }

    & .swiper {
      overflow: visible;

      &-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: var(--section-grid-columns);
        row-gap: var(--grid-row-gap);
        column-gap: var(--grid-column-gap);

        @include mediaTablet {
          display: flex;
          flex-direction: row;
          gap: 0;
        }

        &::before,
        &::after {
          content: "";

          @include mediaTablet {
            content: none;
          }
        }

        &::after {
          grid-column: 1/2;
          grid-row: 1/2;
        }
      }

      &-slide {
        @include mediaTablet {
          max-width: rem(334);
        }

        @include mediaMobile {
          max-width: rem(290);
        }

        & > div {
          max-width: unset;

          pointer-events: all;
        }
      }
    }
  }

  &_sticky {
    overflow: visible;
  }

  &_background {
    background-color: var(--bg-extralight);

    & .review-card,
    & .case-card,
    & .advantages-card,
    & .category-card,
    & .listing-card {
      border-width: 0;
    }
  }
}
