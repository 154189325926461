.checkbox {
  --checkbox-gap: #{rem(18)};
  --checkbox-icon-size: 80%;
  --checkbox-size: #{rem(24)};

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(18);

  cursor: pointer;
  user-select: none;

  @include mediaBigDesktop {
    --checkbox-gap: #{big(18)};
    --checkbox-size: #{big(24)};
  }

  @include mediaBigDesktop {
    --checkbox-gap: #{big(15)};
  }

  @include mediaLaptop {
    --checkbox-gap: #{rem(12)};
  }

  @include mediaMobile {
    --checkbox-gap: #{rem(10)};
  }

  @include hover {
    & .checkbox {
      &__item {
        border-color: var(--accent-primary-default);
      }
    }
  }

  &::before {
    @include pseudo(8, false, true);
  }

  &__input {
    &:checked {
      & ~ .checkbox {
        &__item {
          background-color: var(--accent-primary-default);
          border-color: var(--accent-primary-default);
        }
      }

      &:disabled {
        & ~ .checkbox {
          &__item {
            &::before {
              background-color: var(--stroke-light);
            }
          }
        }
      }
    }

    &:disabled {
      & ~ .checkbox {
        &__item {
          background-color: var(--bg-white);
          border-color: var(--stroke-light);
        }
      }

      & ~ .text {
        color: var(--text-disabled);
      }
    }
  }

  &__item {
    position: relative;

    width: var(--checkbox-size);
    height: var(--checkbox-size);
    display: block;
    flex-shrink: 0;

    background-color: var(--bg-white);
    border: solid 2px var(--stroke-light);
    border-radius: var(--radius-small-elements);

    transition: border-color var(--animation-default),
      background-color var(--animation-default);

    @include mediaBigDesktop {
      border-width: big(2);
    }

    &::before {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      width: var(--checkbox-icon-size);
      height: var(--checkbox-icon-size);

      background-color: var(--bg-white);

      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M13.3346 4L6.0013 11.3333L2.66797 8' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
    }
  }

  & .text {
    color: var(--text-dark-primary);
    font: var(--font-body-M-r);

    & a {
      position: relative;
      z-index: 1;

      color: var(--accent-primary-default);
      text-decoration: none;

      transition: color var(--animation-default);

      @include hover {
        color: var(--accent-primary-hover);
      }
    }
  }
}
