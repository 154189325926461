:root {
  --cubic-bezier: cubic-bezier(.25, .1, .25, 1); // ease
  --cubic-bezier: cubic-bezier(.36, .3, 0, 1); // cool animation

  --animation-duration: 0.5s;
  --animation-duration-medium: 0.7s;
  --animation-duration-long: 1s;

  --animation-default: var(--cubic-bezier) var(--animation-duration);
  --animation-medium: var(--cubic-bezier) var(--animation-duration-medium);
  --animation-long: var(--cubic-bezier) var(--animation-duration-long);
}
