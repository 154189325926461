@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Regular/Manrope-Regular.woff2') format('woff2'),
    url('../fonts/Manrope/Regular/Manrope-Regular.woff') format('woff'),
    url('../fonts/Manrope/Regular/Manrope-Regular.svg') format('svg'),
    url('../fonts/Manrope/Regular/Manrope-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Medium/Manrope-Medium.woff2') format('woff2'),
    url('../fonts/Manrope/Medium/Manrope-Medium.woff') format('woff'),
    url('../fonts/Manrope/Medium/Manrope-Medium.svg') format('svg'),
    url('../fonts/Manrope/Medium/Manrope-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/SemiBold/Manrope-SemiBold.woff2') format('woff2'),
    url('../fonts/Manrope/SemiBold/Manrope-SemiBold.woff') format('woff'),
    url('../fonts/Manrope/SemiBold/Manrope-SemiBold.svg') format('svg'),
    url('../fonts/Manrope/SemiBold/Manrope-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Bold/Manrope-Bold.woff2') format('woff2'),
    url('../fonts/Manrope/Bold/Manrope-Bold.woff') format('woff'),
    url('../fonts/Manrope/Bold/Manrope-Bold.svg') format('svg'),
    url('../fonts/Manrope/Bold/Manrope-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
