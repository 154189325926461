.tag {
  padding: rem(4) rem(20);
  width: fit-content;
  display: block;

  color: var(--text-dark-secondary);
  font: var(--font-body-S-m);
  white-space: nowrap;
  text-transform: lowercase;
  text-decoration: none;

  background-color: var(--bg-light);
  border-radius: var(--radius-infinity);

  @include mediaBigDesktop {
    padding: big(4) big(20);
  }

  @include mediaDesktop {
    padding: rem(4) rem(15) rem(5);

    font: 500 rem(14)/1.55 var(--font-primary);
  }

  @include mediaLaptop {
    padding: 0 rem(10) rem(1);

    // font: 500 rem(12)/1.55 var(--font-primary);
    font: 500 rem(14)/1.55 var(--font-primary);
  }

  // @include mediaTablet {
  //   font: 500 rem(12)/1.5 var(--font-primary);
  // }

  &-list {
    width: 100%;
    display: flex;
    flex-wrap: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }
  }
}
