.article {
  width: 100%;
  display: block;

  ul:not([class]),
  ol:not([class]),
  p:not([class]),
  span:not([class]),
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;

    padding: 0;
    width: 100%;
  }

  p:not([class]),
  span:not([class]) {
    &:not([class*=text_body_]) {
      font: var(--font-body-L-r);
    }
  }

  a:not([class]) {
    color: var(--accent-primary-default);
    font: var(--font-body-L-r);
    font-weight: inherit;
    text-decoration: underline;

    transition: color var(--animation-default);

    @include hover {
      color: var(--text-dark-primary);
    }
  }

  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  h5,
  h6 {
    margin-top: rem(50);

    @include mediaBigDesktop {
      margin-top: big(50);
    }

    @include mediaDesktop {
      margin-top: rem(40);
    }

    @include mediaLaptop {
      margin-top: rem(30);
    }

    @include mediaTablet {
      margin-top: rem(25);
    }

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .h2,
  h2:not([class]) {
    margin-bottom: rem(35);

    @include mediaBigDesktop {
      margin-bottom: big(35);
    }

    @include mediaDesktop {
      margin-bottom: rem(30);
    }

    @include mediaLaptop {
      margin-bottom: rem(25);
    }

    @include mediaTablet {
      margin-bottom: rem(20);
    }
  }

  .h3,
  h3,
  .h4,
  h4,
  h5:not([class]),
  h6:not([class]) {
    margin-bottom: rem(25);

    @include mediaBigDesktop {
      margin-bottom: big(25);
    }

    @include mediaDesktop {
      margin-bottom: rem(20);
    }

    @include mediaLaptop {
      margin-bottom: rem(15);
    }

    @include mediaTablet {
      margin-bottom: rem(12);
    }

    @include mediaMobile {
      margin-bottom: rem(10);
    }
  }

  p:not(.text),
  ul:not([class]),
  ol:not([class]),
  img:not([class]) {
    margin-top: rem(20);

    @include mediaBigDesktop {
      margin-top: big(20);
    }

    @include mediaLaptop {
      margin-top: rem(15);
    }

    @include mediaTablet {
      margin-top: rem(12);
    }

    @include mediaMobile {
      margin-top: rem(10);
    }
  }

  p:not(.text) b,
  p:not(.text) strong,
  li:not([class]) b,
  li:not([class]) strong {
    font-weight: 600;
  }

  ul:not([class]),
  ol:not([class]) {
    & li {
      font: var(--font-body-L-r);
    }
  }

  img:not([class]) {
    width: 100%;
    height: auto;

    pointer-events: none;
    user-select: none;
  }

  figure:not([class]) {
    margin: rem(50) 0;

    padding: 0;
    width: 100%;

    @include mediaBigDesktop {
      margin: big(50) 0;
    }

    @include mediaDesktop {
      margin: rem(40) 0;
    }

    @include mediaLaptop {
      margin: rem(30) 0;
    }

    @include mediaTablet {
      margin: rem(25) 0;
    }

    @include mediaMobile {
      margin: rem(20) 0;
    }

    img {
      margin: 0;

      padding: 0;
    }

    figcaption {
      margin: 0;
      margin-top: rem(20);

      width: 100%;
      display: block;

      color: var(--text-dark-secondary);
      font: var(--font-body-M-r);
      text-align: left;
      text-decoration: none;

      @include mediaBigDesktop {
        margin-top: big(20);
      }

      @include mediaLaptop {
        margin-top: rem(15);
      }

      @include mediaTablet {
        margin-top: rem(10);
      }
    }
  }

  .section {
    --section-margin: #{rem(50)};
    --section-padding: #{rem(20)};

    margin: var(--section-margin) 0;

    @include mediaBigDesktop {
      --section-margin: #{big(50)};
      --section-padding: #{big(20)};
    }

    @include mediaDesktop {
      --section-margin: #{rem(40)};
    }

    @include mediaLaptop {
      --section-margin: #{rem(30)};
    }

    @include mediaTablet {
      --section-margin: #{rem(25)};
      --section-padding: #{rem(10)};
    }

    @include mediaMobile {
      --section-margin: #{rem(20)};
      --section-padding: 0;
    }
  }

  & > * {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .cta-section {
    --padding-top: #{rem(60)};
    --padding-right: #{rem(70)};
    --padding-bottom: #{rem(70)};
    --padding-left: #{rem(70)};

    @include mediaBigDesktop {
      --padding-top: #{big(60)};
      --padding-right: #{big(70)};
      --padding-bottom: #{big(70)};
      --padding-left: #{big(70)};
    }

    @include mediaDesktop {
      --padding-top: #{rem(50)};
      --padding-right: #{rem(60)};
      --padding-bottom: #{rem(60)};
      --padding-left: #{rem(60)};
    }

    @include mediaLaptop {
      --padding-top: #{rem(30)};
      --padding-right: #{rem(40)};
      --padding-bottom: #{rem(40)};
      --padding-left: #{rem(40)};
    }

    @include mediaMobile {
      --padding-top: #{rem(30)};
      --padding-right: #{rem(15)};
      --padding-bottom: #{rem(30)};
      --padding-left: #{rem(15)};
    }

    &__content {
      flex-basis: unset;
    }

    &__title {
      margin: 0;

      font: var(--font-h3);
    }

    &__description {
      max-width: 82%;

      @include mediaDesktop {
        font-size: rem(18);
      }

      @include mediaLaptop {
        max-width: unset;

        font-size: rem(16);
      }

      @include mediaMobile {
        font-size: rem(14);
      }
    }
  }

  .table-responsive {
    margin-top: rem(50);
    margin-bottom: rem(50);

    @include mediaBigDesktop {
      margin-top: big(50);
      margin-bottom: big(50);
    }

    @include mediaDesktop {
      margin-top: rem(40);
      margin-bottom: rem(40);
    }

    @include mediaLaptop {
      margin-top: rem(30);
      margin-bottom: rem(30);
    }

    @include mediaTablet {
      margin-top: rem(25);
      margin-bottom: rem(25);
    }

    @include mediaMobile {
      margin-top: rem(20);
      margin-bottom: rem(20);
    }
  }
}

.article-payments {
  margin-top: rem(30);

  width: auto;
  height: rem(74);

  user-select: none;

  @include mediaBigDesktop {
    margin-top: big(30);

    height: big(74);
  }

  @include mediaDesktop {
    margin-top: rem(20);

    height: rem(54);
  }

  @include mediaLaptop {
    height: rem(44);
  }

  @include mediaTablet {
    margin-top: rem(15);
  }

  @include mediaMobile {
    margin-top: rem(10);

    height: rem(34);
  }
}
