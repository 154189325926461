.price {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(2);

  color: var(--accent-secondary-default);
  font: var(--font-card-title-M);

  &__old {
    position: relative;

    width: fit-content;
    display: block;

    color: var(--text-dark-secondary);
    font: 400 rem(18)/1.5 var(--font-primary);
    text-decoration: none;

    @include mediaBigDesktop {
      gap: big(2);

      font: 400 big(18)/1.5 var(--font-primary);
    }

    @include mediaDesktop {
      font: 400 rem(14)/1.5 var(--font-primary);
    }

    @include mediaMobile {
      // font: 500 rem(12)/1.55 var(--font-primary);
      font: 500 rem(14)/1.55 var(--font-primary);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      width: 100%;
      height: 1px;

      background-color: var(--text-dark-secondary);

      pointer-events: none;
      user-select: none;

      @include mediaBigDesktop {
        height: big(1);
      }
    }
  }
}
