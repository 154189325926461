.hidden-text {
  display: flex;
  flex-direction: column;
  gap: rem(15);

  @include mediaBigDesktop {
    gap: big(15);
  }

  @include mediaLaptop {
    gap: rem(10);
  }

  &__wrapper {
    position: relative;

    width: 100%;
    max-height: rem(500);
    display: flex;

    overflow: hidden;

    transition: max-height var(--animation-default);

    @include mediaBigDesktop {
      max-height: big(500);
    }

    @include mediaLaptop {
      max-height: rem(420);
    }

    &::before {
      @include pseudo(0, false, false) {
        & {
          z-index: 1;

          background-image: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
          opacity: 1;

          transition: opacity var(--animation-default),
            visibility var(--animation-default);
        }
      }
    }
  }

  &__text {
    width: 100%;
    height: 100%;
  }

  &_show {
    & .hidden-text {
      &__wrapper {
        max-height: var(--hidden-text-height);

        &::before {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
}
