.breadcrumbs {
  padding: rem(20) 0;

  background-color: transparent;

  @include mediaBigDesktop {
    padding: big(20) 0;
  }

  @include mediaLaptop {
    padding: rem(15) 0;
  }

  @include mediaTablet {
    padding: rem(12) 0;
  }

  &__list {
    margin: 0;

    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(15);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaMobile {
      row-gap: rem(5);
      column-gap: rem(10);
    }
  }

  &__separator {
    color: var(--text-dark-tertiary);
    font: var(--font-body-S-r);
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaMobile {
      column-gap: rem(10);
    }

    /*&::before {
      content: "/";

      color: var(--text-dark-tertiary);
      font: var(--font-body-S-r);
    }

    &:first-child {
      &::before {
        content: none;
      }
    }*/

    &:last-child {
      & .breadcrumbs {
        &__link {
          pointer-events: none;
        }
      }
    }
  }

  &__link {
    position: relative;

    text-decoration: none;

    @include hover {
      & .breadcrumbs {
        &__text {
          color: var(--accent-primary-default);
        }
      }
    }

    &::before {
      @include pseudo(15, false, true);

      @include mediaMobile {
        @include pseudo(5, true, false);
      }
    }
  }

  &__text {
    color: var(--text-dark-tertiary);
    font: var(--font-body-S-r);

    transition: color var(--animation-default);
  }
}
