.button.button_tertiary {
  --button-gap: #{rem(8)};
  --button-icon-color: var(--accent-primary-default);
  --button-icon-color-hover: var(--accent-primary-hover);
  --button-text-color: var(--accent-primary-default);
  --button-text-color-hover: var(--accent-primary-hover);

  border-radius: 0;

  @include mediaBigDesktop {
    --button-gap: #{big(8)};
  }
}
