:root {
  --font-primary: "Manrope", sans-serif;

  --font-h1-main: 700 #{rem(90)}/1.1 var(--font-primary);
  --font-h1-card: 600 #{rem(60)}/1.2 var(--font-primary);
  --font-h1: 600 #{rem(70)}/1.2 var(--font-primary);
  --font-h2: 600 #{rem(56)}/1.3 var(--font-primary);
  --font-h3: 600 #{rem(42)}/1.4 var(--font-primary);
  --font-h4: 600 #{rem(26)}/1.4 var(--font-primary);
  --font-card-title-L: 600 #{rem(42)}/1.4 var(--font-primary);
  --font-card-title-M: 600 #{rem(26)}/1.3 var(--font-primary);
  --font-card-title: 600 #{rem(24)}/1.5 var(--font-primary);
  --font-body-XL-m: 500 #{rem(26)}/1.5 var(--font-primary);
  --font-body-XL-r: 400 #{rem(26)}/1.5 var(--font-primary);
  --font-body-L-sb: 600 #{rem(20)}/1.5 var(--font-primary);
  --font-body-L-m: 500 #{rem(20)}/1.5 var(--font-primary);
  --font-body-L-r: 400 #{rem(20)}/1.5 var(--font-primary);
  --font-body-M-m: 500 #{rem(20)}/1.5 var(--font-primary);
  --font-body-M-r: 400 #{rem(20)}/1.5 var(--font-primary);
  --font-body-S-m: 500 #{rem(18)}/1.5 var(--font-primary);
  --font-body-S-r: 400 #{rem(18)}/1.5 var(--font-primary);
  --font-body-XS-m: 500 #{rem(16)}/1.5 var(--font-primary);
  --font-body-XXS-m: 500 #{rem(12)}/1.5 var(--font-primary);
  --font-btn: var(--font-body-M-m);

  @include mediaBigDesktop {
    --font-h1-main: 700 #{big(90)}/1.1 var(--font-primary);
    --font-h1-card: 600 #{big(60)}/1.2 var(--font-primary);
    --font-h1: 600 #{big(70)}/1.2 var(--font-primary);
    --font-h2: 600 #{big(56)}/1.3 var(--font-primary);
    --font-h3: 600 #{big(42)}/1.4 var(--font-primary);
    --font-h4: 600 #{big(26)}/1.4 var(--font-primary);
    --font-card-title-L: 600 #{big(42)}/1.4 var(--font-primary);
    --font-card-title-M: 600 #{big(26)}/1.3 var(--font-primary);
    --font-card-title: 600 #{big(24)}/1.5 var(--font-primary);
    --font-body-XL-m: 500 #{big(26)}/1.5 var(--font-primary);
    --font-body-XL-r: 400 #{big(26)}/1.5 var(--font-primary);
    --font-body-L-sb: 600 #{big(20)}/1.5 var(--font-primary);
    --font-body-L-m: 500 #{big(20)}/1.5 var(--font-primary);
    --font-body-L-r: 400 #{big(20)}/1.5 var(--font-primary);
    --font-body-M-m: 500 #{big(20)}/1.5 var(--font-primary);
    --font-body-M-r: 400 #{big(20)}/1.5 var(--font-primary);
    --font-body-S-m: 500 #{big(18)}/1.5 var(--font-primary);
    --font-body-S-r: 400 #{big(18)}/1.5 var(--font-primary);
    --font-body-XS-m: 500 #{big(16)}/1.5 var(--font-primary);
    --font-body-XXS-m: 500 #{big(12)}/1.5 var(--font-primary);
  }

  @include mediaDesktop {
    --font-h1-main: 700 #{rem(70)}/1.1 var(--font-primary);
    --font-h1-card: 600 #{rem(50)}/1.3 var(--font-primary);
    --font-h1: 600 #{rem(54)}/1.2 var(--font-primary);
    --font-h2: 600 #{rem(42)}/1.3 var(--font-primary);
    --font-h3: 600 #{rem(32)}/1.4 var(--font-primary);
    --font-h4: 600 #{rem(22)}/1.4 var(--font-primary);
    --font-card-title-L: 600 #{rem(32)}/1.4 var(--font-primary);
    --font-card-title-M: 600 #{rem(20)}/1.3 var(--font-primary);
    --font-card-title: 600 #{rem(18)}/1.5 var(--font-primary);
    --font-body-XL-m: 500 #{rem(20)}/1.5 var(--font-primary);
    --font-body-XL-r: 400 #{rem(20)}/1.5 var(--font-primary);
    --font-body-L-sb: 600 #{rem(16)}/1.5 var(--font-primary);
    --font-body-L-m: 500 #{rem(16)}/1.5 var(--font-primary);
    --font-body-L-r: 400 #{rem(16)}/1.5 var(--font-primary);
    --font-body-M-m: 500 #{rem(16)}/1.5 var(--font-primary);
    --font-body-M-r: 400 #{rem(16)}/1.5 var(--font-primary);
    --font-body-S-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-S-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XS-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XXS-m: 500 #{rem(12)}/1.5 var(--font-primary);
  }

  @include mediaLaptop {
    --font-h1-main: 700 #{rem(50)}/1.1 var(--font-primary);
    --font-h1-card: 600 #{rem(32)}/1.3 var(--font-primary);
    --font-h1: 600 #{rem(40)}/1.2 var(--font-primary);
    --font-h2: 600 #{rem(32)}/1.3 var(--font-primary);
    --font-h3: 600 #{rem(26)}/1.4 var(--font-primary);
    --font-h4: 600 #{rem(20)}/1.4 var(--font-primary);
    --font-card-title-L: 600 #{rem(24)}/1.4 var(--font-primary);
    --font-card-title-M: 600 #{rem(18)}/1.3 var(--font-primary);
    --font-card-title: 600 #{rem(16)}/1.3 var(--font-primary);
    --font-body-XL-m: 500 #{rem(18)}/1.5 var(--font-primary);
    --font-body-XL-r: 400 #{rem(18)}/1.5 var(--font-primary);
    --font-body-L-sb: 600 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-M-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-M-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-S-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-S-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XS-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XXS-m: 500 #{rem(10)}/1.5 var(--font-primary);
    // --font-body-S-m: 500 #{rem(14)}/1.5 var(--font-primary);
    // --font-body-S-r: 400 #{rem(14)}/1.5 var(--font-primary);
  }

  @include mediaTablet {
    --font-h1-main: 700 #{rem(36)}/1.2 var(--font-primary);
    --font-h1-card: 600 #{rem(24)}/1.3 var(--font-primary);
    --font-h1: 600 #{rem(30)}/1.2 var(--font-primary);
    --font-h2: 600 #{rem(26)}/1.3 var(--font-primary);
    --font-h3: 600 #{rem(20)}/1.4 var(--font-primary);
    --font-h4: 600 #{rem(18)}/1.4 var(--font-primary);
    --font-card-title-L: 600 #{rem(20)}/1.4 var(--font-primary);
    --font-card-title-M: 600 #{rem(18)}/1.3 var(--font-primary);
    --font-card-title: 600 #{rem(16)}/1.5 var(--font-primary);
    --font-body-XL-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XL-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-sb: 600 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-M-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-M-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-S-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-S-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XS-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XXS-m: 500 #{rem(10)}/1.5 var(--font-primary);
  }

  @include mediaMobile {
    --font-h1-main: 700 #{rem(30)}/1.2 var(--font-primary);
    --font-h1-card: 600 #{rem(22)}/1.3 var(--font-primary);
    --font-h1: 600 #{rem(24)}/1.2 var(--font-primary);
    --font-h2: 600 #{rem(22)}/1.3 var(--font-primary);
    --font-h3: 600 #{rem(18)}/1.4 var(--font-primary);
    --font-h4: 600 #{rem(16)}/1.4 var(--font-primary);
    --font-card-title-L: 600 #{rem(20)}/1.4 var(--font-primary);
    --font-card-title-M: 600 #{rem(18)}/1.3 var(--font-primary);
    --font-card-title: 600 #{rem(16)}/1.5 var(--font-primary);
    --font-body-XL-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XL-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-sb: 600 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-L-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-M-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-M-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-S-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-S-r: 400 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XS-m: 500 #{rem(14)}/1.5 var(--font-primary);
    --font-body-XXS-m: 500 #{rem(10)}/1.5 var(--font-primary);
  }
}
