:root {
  --accent-primary-default: #55ABD4;
  --accent-primary-hover: #42A0CC;
  --accent-secondary-default: #A9344E;
  --accent-secondary-hover: #A31B39;
  
  --text-disabled: #C5C5C5;
  --text-light-primary: #FFFFFF;
  --text-light-secondary: #999999;
  --text-light-tertiary: rgba(255, 255, 255, 0.4);
  --text-dark-primary: #000000;
  --text-dark-secondary: #7A7A7A;
  --text-dark-tertiary: #999999;
  
  --bg-dark: #222222;
  --bg-light: #EEF2F5;
  --bg-disabled: #F6F6F6;
  --bg-white: #FFFFFF;
  --bg-extralight: #F7F8FB;
  --bg-black: #000000;
  
  --stroke-dark: rgba(0, 0, 0, 0.5);
  --stroke-light: rgba(27, 68, 81, 0.15);
  
  --system-error: purple;
  --system-red: #FF2C2C;
  --system-green: #40D157;
  --system-yellow: #FFD53F;
}
