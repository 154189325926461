.icon {
  width: rem(20);
  height: rem(20);
  flex-shrink: 0;

  fill: var(--text-dark-primary);

  pointer-events: none;
  user-select: none;

  @include mediaBigDesktop {
    width: big(20);
    height: big(20);
  }

  @include mediaMobile {
    width: rem(16);
    height: rem(16);
  }

  &.icon {
    &_24 {
      width: rem(24);
      height: rem(24);

      @include mediaBigDesktop {
        width: big(24);
        height: big(24);
      }

      @include mediaDesktop {
        width: rem(20);
        height: rem(20);
      }

      @include mediaLaptop {
        width: rem(18);
        height: rem(18);
      }
    }
  }
}
