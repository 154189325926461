.article-navigation {
  --navigation-background: #E5F2FE;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: var(--navigation-background);
  border-radius: var(--radius-common);
  overflow: hidden;

  @include mediaLaptop {
    border-radius: var(--radius-secondary);
  }

  &__image {
    position: relative;

    width: 100%;
    height: auto;
    aspect-ratio: 1234/550;

    overflow: hidden;

    pointer-events: none;
    user-select: none;

    @include mediaDesktop {
      aspect-ratio: 943/450;

      @supports not (aspect-ratio: 1/1) {
        height: rem(450);
      }
    }

    @include mediaLaptop {
      aspect-ratio: 710/360;

      @supports not (aspect-ratio: 1/1) {
        height: rem(360);
      }
    }

    @include mediaTablet {
      aspect-ratio: 688/320;

      @supports not (aspect-ratio: 1/1) {
        height: rem(320);
      }
    }

    @include mediaMobile {
      aspect-ratio: 290/160;

      @supports not (aspect-ratio: 1/1) {
        height: rem(160);
      }
    }

    @supports not (aspect-ratio: 1/1) {
      height: rem(550);

      @include mediaBigDesktop {
        height: big(550);
      }
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      background-color: var(--bg-dark);
    }
  }

  &__content {
    padding: rem(55) rem(70) rem(65);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      padding: big(55) big(70) big(65);
      gap: big(20);
    }

    @include mediaDesktop {
      padding: rem(45) rem(70) rem(55);
      gap: rem(15);
    }

    @include mediaLaptop {
      padding: rem(40) rem(70) rem(50);
    }

    @include mediaTablet {
      padding: rem(30) rem(40) rem(40);
    }

    @include mediaMobile {
      padding: rem(20) rem(20) rem(25);
      gap: rem(10);
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
  }

  &__list {
    list-style-type: none;

    margin: 0;

    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    list-style: none;

    counter-reset: num;

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaDesktop {
      gap: rem(10);
    }

    @include mediaMobile {
      gap: rem(8);
    }
  }

  &__item {
    width: 100%;
    display: block;

    color: var(--accent-primary-default);
    font: var(--font-body-L-r);

    &::before {
      content: counter(num) ".\00a0\00a0";

      position: relative;
      top: 0;
      left: 0;

      display: inline;

      background: transparent;

      counter-increment: num;
    }
  }

  &__link {
    position: relative;

    color: var(--accent-primary-default);
    text-decoration: underline dashed;
    text-decoration-color: rgba(85, 171, 212, 0.50);
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
    text-underline-position: under;

    transition: color var(--animation-default),
      text-decoration-color var(--animation-default);

    @include mediaBigDesktop {
      text-decoration-thickness: big(1);
    }

    @include hover {
      color: var(--text-dark-primary);
      text-decoration-color: var(--text-dark-tertiary);
    }
  }
}
