:root {
  --grid-default-columns: repeat(12, 1fr);
  --grid-column-gap: #{rem(50)};
  --grid-row-gap: #{rem(50)};

  @include mediaBigDesktop {
    --grid-column-gap: #{big(50)};
    --grid-row-gap: #{big(50)};
  }

  @include mediaDesktop {
    --grid-column-gap: #{rem(30)};
    --grid-row-gap: #{rem(30)};
  }

  @include mediaTablet {
    --grid-column-gap: #{rem(20)};
    --grid-row-gap: #{rem(20)};
  }

  @include mediaMobile {
    --grid-default-columns: repeat(4, 1fr);
    --grid-column-gap: #{rem(15)};
    --grid-row-gap: #{rem(10)};
  }
}
