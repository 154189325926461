:root {
  --radius-common: #{rem(25)};
  --radius-secondary: #{rem(20)};
  --radius-tertiary: #{rem(10)};
  --radius-small-elements: #{rem(5)};
  --radius-infinity: #{rem(1600)};

  @include mediaBigDesktop {
    --radius-common: #{big(25)};
    --radius-secondary: #{big(20)};
    --radius-tertiary: #{big(10)};
    --radius-small-elements: #{big(5)};
    --radius-infinity: #{big(1600)};
  }

  @include mediaMobile {
    --radius-common: #{rem(20)};
    --radius-secondary: #{rem(15)};
  }
}
