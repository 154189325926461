@mixin ul-list {
  position: relative;

  padding-left: calc(var(--list-ul-item-gap) + var(--list-circle-size));

  &::before {
    content: "";

    position: absolute;
    top: var(--list-circle-top);
    left: 0;

    width: calc(var(--list-circle-size) - (var(--list-circle-border-size) * 2));
    height: calc(var(--list-circle-size) - (var(--list-circle-border-size) * 2));

    background-color: var(--list-circle-color);
    border: solid var(--list-circle-border-size) var(--list-circle-border-color);
    border-radius: var(--radius-infinity);
  }
}

@mixin ol-list {
  position: relative;

  padding-left: calc(var(--list-ol-item-gap) + var(--list-ol-index-width));

  &::before {
    content: "0" counter(item) ".";

    position: absolute;
    top: 0;
    left: 0;

    width: fit-content;
    min-width: var(--list-ol-index-width);
    height: fit-content;
    display: inline-flex;

    color: var(--list-ol-index-color);
    font: var(--list-ol-index-font);

    background-color: transparent;
    border-radius: unset;

    counter-increment: item;
    user-select: none;
  }

  &:nth-child(1n + 10) {
    &::before {
      content: counter(item) ".";
    }
  }
}

ol.list,
ul.list,
.article ol,
.article ul {
  --list-circle-border-color: var(--accent-primary-default);
  --list-circle-border-size: 1px;
  --list-circle-color: var(--accent-primary-default);
  --list-circle-size: #{rem(5)};
  --list-circle-top: #{rem(9)};
  --list-color: inherit;
  --list-font: var(--font-body-M-r);
  --list-gap: #{rem(10)};
  --list-ol-index-color: var(--accent-primary-default);
  --list-ol-index-font: var(--font-body-M-m);
  --list-ol-index-width: #{rem(30)};
  --list-ol-item-gap: #{rem(20)};
  --list-ol-padding-left: 0;
  --list-ul-item-gap: #{rem(20)};
  --list-ul-padding-left: var(--list-ul-item-gap);

  margin: 0;

  padding: 0;
  padding-left: var(--list-ul-padding-left);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--list-gap);

  list-style: none;

  counter-reset: item;

  @include mediaBigDesktop {
    --list-circle-border-size: #{big(1)};
    --list-circle-size: #{big(5)};
    --list-circle-top: #{big(9)};
    --list-gap: #{big(10)};
    --list-ol-index-width: #{big(30)};
    --list-ol-item-gap: #{big(20)};
    --list-ul-item-gap: #{big(20)};
  }

  @include mediaLaptop {
    --list-circle-top: #{rem(8)};
    --list-ol-index-width: #{rem(26)};
    --list-ul-item-gap: #{rem(15)};
  }

  @include mediaMobile {
    --list-ul-item-gap: #{rem(10)};
    --list-ol-item-gap: #{rem(10)};
  }

  & ul,
  & ol {
    margin-top: var(--list-gap);

    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--list-gap);

    list-style: none;
  }

  & ol {
    counter-reset: item;
  }

  & ul {
    //--list-circle-color: transparent;
  }

  & li {
    width: 100%;
    display: block;

    color: var(--list-color);
    font: var(--list-font);

    &:empty {
      display: none;
    }
  }

  & b,
  & strong {
    font-weight: 700;
  }

  & a {
    color: var(--accent-primary-default);
    text-decoration-color: inherit;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
    text-underline-position: from-font;

    transition: color var(--animation-default);

    @include hover {
      color: inherit;
    }
  }

  #visually-impaired & {
    --list-circle-border-color: var(--accent-primary-default);
    --list-circle-border-size: 1px;
    --list-circle-color: var(--accent-primary-default);
    --list-circle-size: #{rem(10)};
    --list-circle-top: #{rem(20)};
    --list-ol-index-color: var(--accent-primary-default);
    --list-ol-index-width: #{rem(50)};
    --list-ul-padding-left: var(--list-ul-item-gap);

    @include mediaBigDesktop {
      --list-circle-size: #{big(10)};
      --list-circle-top: #{big(20)};
      --list-ol-index-width: #{big(50)};
    }

    @include mediaLaptop {
      --list-circle-top: #{rem(12)};
      --list-ol-index-width: #{rem(40)};
    }

    @include mediaTablet {
      --list-circle-size: #{rem(7)};
      --list-circle-top: #{rem(14)};
      --list-ol-index-width: #{rem(35)};
    }

    @include mediaMobile {
      --list-circle-top: #{rem(10)};
      --list-ol-index-width: #{rem(32)};
    }
  }
}

ul.list,
.article ul {
  & > li,
  & ul > li {
    @include ul-list;
  }

  & ol > li {
    @include ol-list;
  }
}

ol.list,
.article ol {
  padding-left: var(--list-ol-padding-left);

  & > li,
  & ol > li {
    @include ol-list();
  }

  & ul > li {
    @include ul-list();
  }
}