* {
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
}

.page {
  height: 100%;

  color: var(--text-dark-primary);
  font-family: var(--font-primary);
  font-weight: 400;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
  // scroll-behavior: smooth;
  // scrollbar-gutter: stable;

  scroll-padding: rem(150);

  @include mediaBigDesktop {
    scroll-padding: big(150);
  }

  @include mediaDesktop {
    scroll-padding: rem(120);
  }

  @include mediaLaptop {
    scroll-padding: rem(90);
  }

  @include mediaTablet {
    scroll-padding: rem(80);
  }

  @include mediaMobile {
    scroll-padding: rem(70);
  }
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

::selection {
  color: var(--text-light-primary);
  ;

  background: var(--accent-primary-default);
}

::-moz-selection {
  color: var(--text-light-primary);
  ;

  background: var(--accent-primary-default);
}

.page__body {
  margin: 0;

  min-width: 320px;
  min-height: 100%;

  font-size: 16px;

  background-color: var(--bg-white);

  @include scrollbar();
}

.disable-scroll {
  width: 100%;
  height: 100vh;

  overflow: hidden;
}

.wrapper {
  position: relative;
  z-index: 1;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.footer {
  margin-top: auto;
}

input {
  appearance: none;
  -webkit-appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration,
  &::-webkit-search-cancel-button {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type=number] {
    appearance: none;
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
}

.pagination {
  margin: 0;
}

fieldset {
  padding: 0;

  border: none;
}

.visually-hidden {
  position: absolute;
  margin: -1px;

  padding: 0;
  width: 1px;
  height: 1px;

  border: 0;
  overflow: hidden;

  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;

  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

  & .container {
    margin: 0;

    padding: 0;
    max-width: unset;
  }
}

.reset-styles {
  margin: 0;

  padding: 0;
}

.disabled {
  opacity: 0.5;

  transform: opacity var(--animation-default);

  pointer-events: none;
}

.elite-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  height: 100%;

  background-color: #000;
  visibility: hidden;
  opacity: 0;

  transition: opacity var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing),
    visibility var(--animation-timing-medium) var(--cubic-bezier) var(--animation-timing);

  cursor: pointer;
  pointer-events: none;

  &_show {
    visibility: visible;
    opacity: 0.1;

    pointer-events: all;
  }
}

.swiper {
  &-slide {
    height: auto;
    display: flex;
    align-items: stretch;
  }

  &__pagination {
    --swiper-pagination-gap: #{rem(10)};

    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--swiper-pagination-gap);

    user-select: none;

    @include mediaBigDesktop {
      --swiper-pagination-gap: #{big(10)};
    }

    @include mediaTablet {
      margin-top: rem(30);

      display: flex;
    }

    @include mediaMobile {
      margin-top: rem(25);
    }

    &.swiper-pagination-fraction {
      display: flex;
      gap: 0;

      color: var(--text-dark-primary);
      font: var(--font-body-S-r);

      @include mediaMobile {
        gap: var(--swiper-pagination-gap);
      }
    }
  }

  &__bullet {
    position: relative;

    width: rem(7);
    height: rem(7);

    background-color: var(--stroke-light);
    border-radius: var(--radius-infinity);
    opacity: 1;

    transition: width var(--animation-default),
      background-color var(--animation-default);

    cursor: pointer;

    @include mediaBigDesktop {
      width: big(7);
      height: big(7);
    }

    @include hover {
      background-color: var(--text-disabled);
    }

    &::before {
      @include pseudo(5, false, true);
    }

    &_active {
      background-color: var(--accent-primary-default);

      @include hover {
        background-color: var(--accent-primary-default);
      }
    }
  }
}

.image {
  margin: 0;

  padding: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(10);

  user-select: none;

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include mediaMobile {
    gap: rem(5);
  }

  &:not(a) {
    pointer-events: none;
  }

  & img,
  & picture {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    object-position: center;
  }

  & > .text {
    width: 100%;
    display: block;

    color: var(--text-dark-tertiary);
    font: var(--font-body-S-r);

    pointer-events: all;
    user-select: text;
  }
}

[data-hover-active] {
  .js-hidden {
    position: relative;
    z-index: 1;

    width: 100%;
    height: 0;
    display: block;
    box-sizing: content-box;

    overflow: hidden;

    transition: height var(--animation-default),
      padding-top var(--animation-default);
  }
}

[class*=__youtube] {
  --social-list-color: var(--social-youtube) !important;
}

[class*=__facebook] {
  --social-list-color: var(--social-facebook) !important;
}

[class*=__ok] {
  --social-list-color: var(--social-ok) !important;
}

[class*=__twitter] {
  --social-list-color: var(--social-twitter) !important;
}

[class*=__tg] {
  --social-list-color: var(--social-tg) !important;
}

[class*=__vk] {
  --social-list-color: var(--social-vk) !important;
}

[class*=__whatsapp] {
  --social-list-color: var(--social-whatsapp) !important;
}
