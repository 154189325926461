.table-responsive {
  --table-background-primary: var(--bg-white);
  --table-background-secondary: var(--bg-extralight);
  --table-border-color: var(--stroke-light);
  --table-border-color-accent: var(--accent-primary-default);
  --table-font: var(--font-body-L-r);
  --table-font-accent: var(--font-body-L-sb);
  --table-min-width: #{rem(688)};
  --table-padding-horizontal: #{rem(25)};
  --table-padding-vertical: #{rem(15)};
  --table-text-color-primary: var(--text-dark-primary);
  --table-text-color-secondary: var(--table-text-color-primary);

  position: relative;

  width: 100%;

  overflow: auto;

  @include scrollbarDelete;

  @include mediaBigDesktop {
    --table-min-width: #{big(688)};
    --table-padding-horizontal: #{big(25)};
    --table-padding-vertical: #{big(15)};
  }

  @include mediaDesktop {
    --table-padding-vertical: #{rem(12)};
  }

  @include mediaLaptop {
    --table-padding-horizontal: #{rem(20)};
  }

  @include mediaTablet {
    --table-padding-horizontal: #{rem(15)};

    right: var(--container-offset);

    padding-right: var(--container-offset);
    padding-left: var(--container-offset);
    width: calc(100% + (var(--container-offset) * 2));
  }

  & table {
    border-collapse: collapse;
    border-spacing: 0;

    width: 100%;
    min-width: var(--table-min-width);

    background-color: var(--table-background-primary);
    border: none;
    border-radius: 0;
  }

  & tr {
    width: 100%;

    border: none;

    &:first-of-type {
      & td {
        color: var(--table-text-color-primary);
        font: var(--table-font-accent);

        &::before {
          content: "";

          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 2px;

          background-color: var(--table-border-color-accent);

          @include mediaBigDesktop {
            height: big(2);
          }
        }
      }
    }

    &:nth-of-type(2n + 1) {
      & td {
        background-color: var(--table-background-secondary);
      }
    }
  }

  & td {
    position: relative;

    padding: var(--table-padding-vertical) var(--table-padding-horizontal);

    color: var(--table-text-color-secondary);
    font: var(--table-font);
    vertical-align: top;

    border: 0;

    &:first-of-type {
      color: var(--table-text-color-primary);
      font: var(--table-font-accent);
    }

    &::after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 1px;

      background-color: var(--table-border-color);

      @include mediaBigDesktop {
        height: big(1);
      }
    }
  }
}
