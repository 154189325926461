.button.button_icon {
  --button-bg-color: var(--stroke-light);
  --button-bg-color-hover: var(--accent-primary-default);
  --button-icon-color: var(--accent-primary-default);
  --button-icon-color-hover: var(--accent-primary-default);

  width: rem(64);
  height: rem(64);

  background-color: var(--bg-white);
  border: solid 1px var(--button-bg-color);
  border-radius: var(--radius-infinity);

  transition: border-color var(--animation-default),
    background-color var(--animation-default);

  @include mediaBigDesktop {
    width: big(64);
    height: big(64);
  }

  @include mediaDesktop {
    width: rem(54);
    height: rem(54);
  }

  @include mediaLaptop {
    width: rem(44);
    height: rem(44);
  }

  @include hover {
    background-color: var(--bg-white);
    border-color: var(--button-bg-color-hover);

    & .icon {
      fill: var(--button-icon-color-hover);
    }
  }

  &[data-button-animation] {
    --button-icon-color-hover: var(--text-light-primary);
  }
}
