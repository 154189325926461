:root {
  --shadow-default: 0 15px 20px 0 rgba(0, 0, 0, 0.04),
    0 5px 6px 0 rgba(0, 0, 0, 0.02),
    0 0 1px 0 rgba(0, 0, 0, 0.04);
  --shadow-large: 0 16px 24px 0 rgba(0, 0, 0, 0.06),
    0 2px 6px 0 rgba(0, 0, 0, 0.04),
    0 0 1px 0 rgba(0, 0, 0, 0.04);
  --shadow-small: 0 4px 8px 0 rgba(0, 0, 0, 0.04),
    0 0 2px 0 rgba(0, 0, 0, 0.06),
    0 0 1px 0 rgba(0, 0, 0, 0.04);

  @include mediaBigDesktop {
    --shadow-default: 0 #{big(15)} #{big(20)} 0 rgba(0, 0, 0, 0.04),
    0 #{big(5)} #{big(6)} 0 rgba(0, 0, 0, 0.02),
    0 0 #{big(1)} 0 rgba(0, 0, 0, 0.04);
    --shadow-large: 0 #{big(16)} #{big(24)} 0 rgba(0, 0, 0, 0.06),
    0 #{big(2)} #{big(6)} 0 rgba(0, 0, 0, 0.04),
    0 0 #{big(1)} 0 rgba(0, 0, 0, 0.04);
    --shadow-small: 0 #{big(4)} #{big(8)} 0 rgba(0, 0, 0, 0.04),
    0 0 #{big(2)} 0 rgba(0, 0, 0, 0.06),
    0 0 #{big(1)} 0 rgba(0, 0, 0, 0.04);
  }
}
