.text-input {
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(8);

  @include mediaBigDesktop {
    gap: big(8);
  }

  &__subtitle {
    order: 1;

    color: var(--text-dark-primary);
    font: var(--font-body-M-r);
  }

  &__input {
    order: 2;

    padding: rem(16) rem(29);
    width: 100%;

    color: var(--text-dark-primary);
    font: var(--font-body-M-r);

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-light);
    border-radius: var(--radius-infinity);
    outline: none;

    transition: border-color var(--animation-default),
      background-color var(--animation-default),
      color var(--animation-default);

    resize: none;

    @include mediaBigDesktop {
      padding: big(16) big(29);

      border-width: big(1);
    }

    @include mediaDesktop {
      padding: rem(14) rem(19);
    }

    @include mediaLaptop {
      padding: rem(10.5) rem(19);
    }

    @include mediaMobile {
      padding: rem(12) rem(19);
    }

    @include hover {
      border-color: var(--accent-primary-default);
    }

    &:focus {
      border-color: var(--accent-primary-default);
    }

    &:disabled {
      color: var(--text-disabled);

      background-color: var(--bg-disabled);
      border-color: var(--stroke-light);

      &::placeholder {
        color: var(--text-disabled);
      }

      & ~ .text-input {
        &__subtitle {
          color: var(--text-disabled);
        }
      }
    }

    &::placeholder {
      color: var(--text-dark-tertiary);
    }
  }

  &_datetime {
    position: relative;

    width: 100%;
    height: rem(66);

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-light);
    border-radius: var(--radius-infinity);

    transition: border-color var(--animation-default);

    @include mediaBigDesktop {
      height: big(66);

      border-width: big(1);
    }

    @include mediaDesktop {
      height: rem(56);
    }

    @include mediaTablet {
      height: rem(46);
    }

    @include hover {
      border-color: var(--accent-primary-default);
    }

    & .text-input {
      &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        width: 100%;
        height: 100%;

        opacity: 0;

        cursor: pointer;

        &::-webkit-calendar-picker-indicator {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          width: auto;
          height: auto;

          color: transparent;

          background: 0 0;

          cursor: pointer;
        }

        &::-webkit-clear-button,
        &::-webkit-inner-spin-button {
          z-index: -10;
        }
      }

      &__placeholder,
      &__current-text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        margin: auto;

        padding: 0 0 0 rem(30);
        width: 100%;
        height: fit-content;
        display: block;

        color: var(--text-dark-tertiary);
        font: var(--font-body-M-r);
        text-overflow: ellipsis;
        white-space: nowrap;

        overflow: hidden;

        transition: opacity var(--animation-default);

        @include mediaBigDesktop {
          padding: 0 0 0 big(30);
        }

        @include mediaDesktop {
          padding: 0 0 0 rem(20);
        }
      }

      &__current-text {
        color: var(--text-dark-primary);

        opacity: 0;
      }
    }

    &.value {
      & .text-input {
        &__placeholder {
          opacity: 0;
        }

        &__current-text {
          opacity: 1;
        }
      }
    }

    &.common-input_error {
      & .common-input__error {
        position: relative;
        top: calc(100% + 5px);

        @include mediaBigDesktop {
          top: calc(100% + #{big(5)});
        }
      }
    }
  }
}

.common-input {
  &__icon {
    flex-shrink: 0;
    fill: var(--system-red);
  }
  
  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    margin-top: rem(-10);
    padding: rem(16) rem(29);
    
    order: 3;

    width: 100%;
    display: flex;
    flex-direction: row;
    gap: rem(10);
    align-items: center;
    justify-content: flex-start;
    
    border-radius: var(--radius-infinity);
    background-color: var(--bg-white);
    box-shadow: 0px 4px 8px 4px rgba(142, 141, 208, 0.16);

    color: var(--text-dark-primary);

    transition: margin-top var(--animation-default),
      opacity var(--animation-default),
      visibility var(--animation-default);
      
    pointer-events: none;
    opacity: 0;
    visibility: hidden;

    @include mediaBigDesktop {
      margin-top: big(-10);
      padding: big(16) big(29);
      gap: big(10);

      box-shadow: 0px big(4) big(8) big(4) rgba(142, 141, 208, 0.16);
    }
    
    @include mediaDesktop {
      padding: rem(14) rem(19);
    }

    @include mediaLaptop {
      padding: rem(10.5) rem(19);
    }

    @include mediaMobile {
      padding: rem(12) rem(19);
    }
  }

  &_error {
    & .common-input {
      &__error {
        margin-top: rem(10);

        opacity: 1 !important;
        visibility: visible;

        pointer-events: all;
      }
    }

    & > .text-input__input {
      border-color: var(--system-red);
    }
  }
}
