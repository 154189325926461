.tooltip {
  position: relative;
  top: rem(3);
  z-index: 2;
  margin-left: rem(8);

  display: inline-flex;

  @include mediaBigDesktop {
    top: big(3);
    margin-left: big(8);
  }

  @include mediaDesktop {
    top: rem(2);
  }

  @include mediaMobile {
    top: rem(3);
    margin-left: rem(2);
  }

  &_active {
    z-index: 3;

    & .tooltip {
      &__button {
        & .icon {
          fill: var(--accent-primary-default);
        }
      }

      &__text {
        box-shadow: var(--shadow-small);
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__button {
    position: relative;

    width: rem(20);
    height: rem(20);

    overflow: visible;

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }

    @include mediaDesktop {
      width: rem(18);
      height: rem(18);
    }

    @include mediaTablet {
      width: rem(16);
      height: rem(16);
    }

    &:before {
      @include pseudo(10, false, true);
    }

    & .icon {
      width: 100%;
      height: 100%;

      fill: var(--text-dark-primary);

      transition: fill var(--animation-default);
    }
  }

  &__text {
    padding: var(--radius-common);
    width: rem(400);

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-light);
    border-radius: var(--radius-common);
    visibility: hidden;
    opacity: 0;

    transition: opacity var(--animation-default),
      visibility var(--animation-default),
      box-shadow var(--animation-long);

    user-select: none;

    @include mediaBigDesktop {
      width: big(400);

      border-width: big(1);
    }

    @include mediaLaptop {
      padding: var(--radius-secondary);

      border-radius: var(--radius-secondary);
    }

    @include media(#{rem(450)}) {
      width: calc(100vw - (var(--container-offset) * 2));
    }

    &.text {
      color: var(--text-dark-primary);
      font: var(--font-body-S-r);
    }
  }
}
