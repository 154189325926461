:root {
  --content-width: #{rem(1966)};
  --container-offset: #{rem(40)};
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  @include mediaBigDesktop {
    --content-width: #{big(1966)};
    --container-offset: #{big(40)};
  }

  @include mediaDesktop {
    --content-width: #{rem(1520)};
  }

  @include mediaLaptop {
    --content-width: #{rem(1200)};
  }

  @include mediaMobile {
    --container-offset: #{rem(15)};
  }
}
