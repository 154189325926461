.submenu {
  margin: 0;

  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: rem(15);

  list-style: none;

  @include mediaBigDesktop {
    gap: big(15);
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    &_grey {
      & .submenu {
        &__link {
          color: var(--text-dark-tertiary);

          @include hover {
            color: var(--accent-primary-default);
          }

          &_active {
            color: var(--accent-primary-default);
          }
        }
      }
    }

    & .submenu {
      padding-left: rem(20);

      @include mediaBigDesktop {
        padding-left: big(20);
      }

      @include mediaTablet {
        padding-left: rem(20);
      }
    }

    & .submenu__wrapper {
      display: none;
    }

    &.show {
      & .submenu__wrapper {
        display: flex;
      }

      & > .submenu__link .submenu-item__more {
        transform: rotate(180deg);

        & > .icon {
          fill: var(--accent-primary-default);
        }
      }
    }
  }

  &__wrapper {
    overflow: hidden;
  }

  &__link {
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    color: var(--text-dark-primary);
    text-decoration: none;

    transition: color var(--animation-default);

    @include hover {
      color: var(--accent-primary-default);
    }

    &::before {
      @include pseudo(7.5, false, true);
    }

    &_active {
      color: var(--accent-primary-default);
    }

    & > .submenu-item__more {
      position: relative;

      height: fit-content;
      display: flex;
      align-items: start;
      justify-content: start;

      transition: transform var(--animation-default);

      &::before {
        @include pseudo(10, false, true);
      }

      & > .icon {
        width: rem(24);
        height: rem(24);

        fill: var(--stroke-dark);

        transition: fill var(--animation-default);

        @include mediaBigDesktop {
          width: big(24);
          height: big(24);
        }

        @include mediaLaptop {
          width: rem(20);
          height: rem(20);
        }
      }
    }
  }
}
