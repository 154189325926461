.tab {
  width: fit-content;
  height: fit-content;
  display: block;

  text-decoration: none;

  border-radius: var(--radius-infinity);

  cursor: pointer;
  user-select: none;

  @include hover {
    & .tab {
      &__content {
        border-color: var(--accent-primary-default);
      }
    }
  }

  &[data-button-animation] {
    @include hover {
      & .tab__content {
        color: var(--text-light-primary);
      }
    }
  }

  @media (any-hover: hover) {
    --before-left: 0;
    --before-scale: 0;
    --before-size: 0;
    --before-top: 0;
  }

  &__content {
    position: relative;

    padding: rem(9) rem(24);
    width: fit-content;
    height: fit-content;
    min-height: rem(56);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: var(--text-dark-primary);
    font: var(--font-body-S-r);
    white-space: nowrap;

    background-color: transparent;
    border: solid 1px var(--stroke-light);
    border-radius: var(--radius-infinity);
    overflow: hidden;

    transition: background-color var(--animation-default),
      color var(--animation-default),
      border-color var(--animation-default);

    pointer-events: none;

    @include mediaBigDesktop {
      padding: big(10) big(24);
      min-height: big(56);

      border-width: big(1);
    }

    @include mediaDesktop {
      padding: rem(9) rem(19);
      min-height: rem(46);
    }

    @include mediaLaptop {
      padding: rem(3) rem(14);
      min-height: rem(40);
    }

    @include mediaTablet {
      min-height: rem(36);
    }

    @include mediaMobile {
      padding: rem(3) rem(11);
      min-height: rem(30);
    }

    @media (any-hover: hover) {
      &::before {
        content: "";

        position: absolute;
        top: var(--before-top);
        left: var(--before-left);
        z-index: -1;

        width: var(--before-size);
        height: var(--before-size);

        background-color: var(--accent-primary-default);
        border-radius: var(--radius-infinity);

        transform: translate(-50%, -50%);
        transition: opacity var(--animation-default),
          background-color var(--animation-default);

        pointer-events: none;
        user-select: none;
      }
    }
  }

  &_active {
    & .tab {
      &__content {
        color: var(--text-light-primary);

        background-color: var(--accent-primary-default);
        border-color: var(--accent-primary-default);
      }
    }
  }

  &__input {
    &:checked {
      & ~ .tab {
        &__content {
          color: var(--text-light-primary);

          background-color: var(--accent-primary-default);
          border-color: var(--accent-primary-default);
        }
      }
    }

    &:disabled {
      & ~ .tab {
        &__content {
          color: var(--text-disabled);

          background-color: var(--bg-white);
          border-color: var(--stroke-light);

          cursor: not-allowed;
        }
      }
    }
  }

  &_collapse {
    --tab-bg-color: var(--accent-primary-default);

    & .tab {
      &__content {
        padding: 0 rem(10);
        width: 100%;
        min-width: rem(64);
        min-height: rem(64);
        display: flex;
        align-items: center;
        justify-content: center;

        @include mediaBigDesktop {
          padding: 0 big(10);
          min-width: big(64);
          min-height: big(64);
        }

        @include mediaDesktop {
          min-width: rem(54);
          min-height: rem(54);
        }

        @include mediaLaptop {
          min-width: rem(44);
          min-height: rem(44);
        }

        @include hover {
          border-color: var(--tab-bg-color);
        }

        &_phone {
          --tab-bg-color: var(--accent-primary-default);
        }

        &_whatsapp {
          --tab-bg-color: #60D669;
        }

        &_tg {
          --tab-bg-color: #25A0E5;
        }

        & .text {
          display: block;

          font: var(--font-body-M-m);
          white-space: nowrap;

          overflow: hidden;

          & > span {
            padding-left: rem(10);
            width: fit-content;
            display: block;

            @include mediaBigDesktop {
              padding-left: big(10);
            }

            @include mediaMobile {
              padding-left: rem(8);
            }
          }
        }

        & .icon {
          flex-shrink: 0;

          fill: var(--tab-bg-color);

          transition: fill var(--animation-default);

          @include mediaLaptop {
            width: rem(20);
            height: rem(20);
          }

          @include mediaMobile {
            width: rem(16);
            height: rem(16);
          }
        }
      }

      &__input {
        &:checked {
          & ~ .tab__content {
            background-color: var(--tab-bg-color);
            border-color: var(--tab-bg-color);

            & .text {
              color: var(--text-light-primary);
            }

            & .icon {
              fill: var(--text-light-primary);
            }
          }
        }
      }
    }
  }
}
