:root {
  --section-padding: #{rem(130)};
  --section-gap: #{rem(70)};

  @include mediaBigDesktop {
    --section-padding: #{big(130)};
    --section-gap: #{big(70)};
  }

  @include mediaDesktop {
    --section-padding: #{rem(120)};
    --section-gap: #{rem(60)};
  }

  @include mediaLaptop {
    --section-padding: #{rem(90)};
    --section-gap: #{rem(45)};
  }

  @include mediaTablet {
    --section-padding: #{rem(70)};
    --section-gap: #{rem(35)};
  }

  @include mediaMobile {
    --section-padding: #{rem(50)};
    --section-gap: #{rem(25)};
  }
}
