.view-count {
  padding: rem(2) rem(15);
  width: fit-content;
  height: rem(37);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: rem(5);

  background-color: var(--bg-light);
  border-radius: var(--radius-infinity);
  overflow: hidden;

  user-select: none;

  @include mediaBigDesktop {
    padding: big(2) big(15);
    height: big(37);
    gap: big(5);
  }

  @include mediaDesktop {
    height: rem(30);
  }

  @include mediaLaptop {
    height: rem(28);
  }

  & .icon {
    fill: var(--accent-primary-default);

    @include mediaDesktop {
      width: rem(16);
      height: rem(16);
    }

    @include mediaLaptop {
      width: rem(14);
      height: rem(14);
    }
  }

  & .text {
    color: var(--text-dark-tertiary);
    font: var(--font-body-S-r);
  }
}
