.select-input {
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(8);

  @include mediaBigDesktop {
    gap: big(8);
  }

  &_checkbox {
    & .select {
      &-answer {
        &__text {
          display: flex;
          align-items: center;
          gap: rem(15);

          font: var(--font-body-S-r);

          @include mediaBigDesktop {
            gap: big(15);
          }

          & .select-answer__checkbox {
            position: relative;

            width: rem(24);
            height: rem(24);
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: var(--bg-white);
            border: solid 2px var(--stroke-light);
            border-radius: var(--radius-small-elements);

            transition: background-color var(--animation-default),
              border-color var(--animation-default);

            @include mediaBigDesktop {
              width: big(24);
              height: big(24);

              border-width: big(2);
            }

            @include mediaLaptop {
              width: rem(20);
              height: rem(20);
            }

            &:before {
              content: "";

              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;

              width: rem(16);
              height: rem(16);
              display: flex;
              flex-shrink: 0;

              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M13.3346 4L6.0013 11.3333L2.66797 8' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;

              @include mediaBigDesktop {
                width: big(16);
                height: big(16);
              }

              @include mediaLaptop {
                width: rem(12);
                height: rem(12);
              }
            }
          }
        }

        &__input {
          &:checked {
            & ~ .select-answer__text {
              color: var(--accent-primary-default);

              & .select-answer__checkbox {
                background-color: var(--accent-primary-default);
                border-color: var(--accent-primary-default);
              }
            }
          }
        }
      }
    }
  }

  &__subtitle {
    color: var(--text-dark-primary);
  }

  &__checkbox {

    &:focus,
    &:checked {
      & ~ .select-current {
        border-color: var(--accent-primary-default);

        & .select-current {
          &__icon {
            transform: rotate(90deg);
          }
        }
      }

      & ~ .select-answer {
        margin-top: rem(10);

        visibility: visible;
        opacity: 1 !important;

        @include mediaBigDesktop {
          margin-top: big(10);
        }
      }
    }

    &:disabled {
      & ~ .select-current {
        background-color: var(--bg-disabled);
        border-color: var(--stroke-light);

        pointer-events: none;

        & .select-current {
          &__text {
            color: var(--text-disabled);
          }

          &__icon {
            fill: var(--text-disabled);
          }
        }
      }
    }
  }

  &.common-input_error {
    & > .select-input__current {
      border-color: var(--system-red);
    }
  }
}

.select-current {
  padding: rem(16) rem(29);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: rem(15);

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-light);
  border-radius: var(--radius-infinity);

  transition: border-color var(--animation-default);

  cursor: pointer;

  @include mediaBigDesktop {
    padding: big(16) big(29);

    border-width: big(1);
  }

  @include mediaDesktop {
    padding: rem(14) rem(19);
  }

  @include mediaLaptop {
    padding: rem(10.5) rem(19);
    min-height: rem(44);
  }

  @include hover {
    border-color: var(--accent-primary-default);
  }

  &__icon {
    width: rem(20);
    height: rem(20);
    flex-shrink: 0;

    fill: var(--accent-primary-default);

    transform: rotate(-90deg);
    transition: transform var(--animation-default);

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }

    @include mediaMobile {
      width: rem(16);
      height: rem(16);
    }
  }

  &__text {
    width: 100%;
    max-width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-body-M-r);
    text-overflow: ellipsis;
    white-space: nowrap;

    overflow: hidden;

    transition: color var(--animation-default);

    user-select: none;

    &.placeholder {
      color: var(--text-dark-tertiary);
    }
  }
}

.select-answer {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 4;
  margin-top: rem(-10);

  padding: rem(12) 0;
  width: 100%;
  max-height: rem(280);
  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow-large);
  background-color: var(--bg-white);
  border-radius: var(--radius-tertiary);
  overflow: auto;
  visibility: hidden;
  opacity: 0;

  transition: margin-top var(--animation-default),
    opacity var(--animation-default),
    visibility var(--animation-default);

  @include scrollbar();

  @include mediaBigDesktop {
    margin-top: big(-10);

    padding: big(12) 0;
    max-height: big(280);
  }

  @include mediaLaptop {
    padding: rem(10) 0;
  }

  &__item {
    z-index: 1;

    padding: rem(13) rem(20);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: var(--text-dark-primary);
    font: var(--font-body-S-r);
    text-align: start;
    text-decoration: none;

    background-color: var(--bg-white);
    border: none;

    transition: background-color var(--animation-default),
      color var(--animation-default);

    cursor: pointer;

    @include mediaBigDesktop {
      padding: big(13) big(20);
    }

    @include mediaLaptop {
      padding: rem(10.5) rem(17);
    }

    @include hover {
      color: var(--accent-primary-default);

      background-color: var(--bg-light);

      & .select-answer__checkbox {
        border-color: var(--accent-primary-default);
      }
    }

    &:focus,
    &:active {
      color: var(--accent-primary-default);

      background-color: var(--bg-light);
    }
  }
}
