.button.button_secondary {
  --button-bg-color: var(--text-dark-primary);
  --button-bg-color-hover: var(--accent-primary-hover);
  --button-border-width: 1px;
  --button-icon-color: var(--text-dark-primary);
  --button-icon-color-hover: var(--text-light-primary);
  --button-text-color: var(--text-dark-primary);
  --button-text-color-hover: var(--text-light-primary);

  padding: 0 calc(var(--button-padding) - var(--button-border-width));
  min-height: var(--button-min-height);

  background-color: transparent;
  border: solid var(--button-border-width) var(--button-bg-color);

  @include mediaBigDesktop {
    --button-border-width: #{big(1)};
  }

  @include hover {
    color: var(--button-text-color-hover);

    background-color: transparent;
    border-color: var(--button-bg-color-hover);

    &:not([data-button-animation]) {
      --button-icon-color-hover: var(--accent-primary-hover);
      --button-text-color-hover: var(--accent-primary-hover);
    }

    & .icon {
      fill: var(--button-icon-color-hover);
    }
  }

  &:disabled,
  &.disabled {
    border-color: var(--stroke-light);
  }
}
