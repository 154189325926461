.logo {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: rem(30);

  user-select: none;

  @include mediaBigDesktop {
    gap: big(30);
  }

  @include mediaDesktop {
    gap: rem(25);
  }

  @include mediaLaptop {
    gap: rem(15);
  }

  &__link {
    &.button {
      --button-icon-color: var(--accent-primary-default);
      --button-icon-color-hover: var(--accent-primary-default);
      --button-bg-color: var(--text-dark-primary);
      --button-bg-color-hover: var(--accent-primary-default);

      width: rem(95);
      height: rem(50);
      flex-shrink: 0;

      border-radius: 0;
      border: none;
      background-color: var(--button-bg-color);
      mask-image: url(../img/logo_mask.svg);
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;

      @include hover {
        background-color: var(--button-bg-color);
      }

      @include mediaBigDesktop {
        width: big(95);
        height: big(50);
      }

      @include mediaDesktop {
        width: rem(76);
        height: rem(40);
      }

      @include mediaLaptop {
        width: rem(66.5);
        height: rem(35);
      }

      @include mediaMobile {
        width: rem(57);
        height: rem(30);
      }

      & .icon {
        display: none;
      }
    }
  }

  &__text {
    width: fit-content;
    max-width: rem(185);

    color: var(--text-dark-tertiary);
    font: var(--font-body-M-r);

    pointer-events: none;

    @include mediaBigDesktop {
      max-width: big(185);
    }

    @include mediaDesktop {
      max-width: rem(160);
    }

    @include mediaLaptop {
      max-width: rem(108);

      font: var(--font-body-S-r);
    }

    @include mediaTablet {
      display: none;
    }
  }
}
