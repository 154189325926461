.button.button_primary {
  --button-bg-color: var(--accent-secondary-default);
  --button-bg-color-hover: var(--accent-secondary-hover);
  --button-icon-color: var(--text-light-primary);
  --button-icon-color-hover: var(--text-light-primary);
  --button-text-color: var(--text-light-primary);
  --button-text-color-hover: var(--text-light-primary);

  padding: 0 var(--button-padding);
  min-height: var(--button-min-height);

  &:disabled,
  &.disabled {
    background-color: var(--bg-disabled);
  }
}
