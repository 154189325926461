.cta-sidebar {
  position: sticky;
  top: calc(var(--global-header-height) + #{rem(30)});
  left: 0;

  padding: rem(49);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(30);

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-light);
  border-radius: var(--radius-common);

  transition: box-shadow var(--animation-default);

  @include mediaBigDesktop {
    top: calc(var(--global-header-height) + #{big(30)});

    padding: big(49);
    gap: big(30);

    border-width: big(1);
  }

  @include mediaDesktop {
    padding: rem(39);
  }

  @include mediaLaptop {
    padding: rem(29);
    gap: rem(20);

    border-radius: var(--radius-secondary);
  }

  @include mediaTablet {
    padding-top: rem(25);
  }

  @include mediaMobile {
    padding: rem(25) rem(15) rem(30);
    gap: rem(15);
  }

  @include hover {
    box-shadow: var(--shadow-small);
  }

  &_no_sticky {
    position: static;
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaDesktop {
      gap: rem(15);
    }

    @include mediaLaptop {
      gap: rem(20);
    }

    @include mediaTablet {
      gap: rem(15);
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-card-title-L);
  }

  &__person {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(25);

    @include mediaBigDesktop {
      gap: big(25);
    }

    @include mediaLaptop {
      gap: rem(20);
    }

    @include mediaTablet {
      gap: rem(25);
    }

    @include mediaMobile {
      gap: rem(15);
    }
  }

  &__image {
    position: relative;

    width: rem(110);
    height: rem(110);
    flex-shrink: 0;

    border-radius: var(--radius-infinity);
    overflow: hidden;

    @include mediaBigDesktop {
      width: big(110);
      height: big(110);
    }

    @include mediaDesktop {
      width: rem(90);
      height: rem(90);
    }

    @include mediaLaptop {
      width: rem(100);
      height: rem(100);
    }

    @include mediaTablet {
      width: rem(80);
      height: rem(80);
    }

    @include mediaMobile {
      width: rem(84);
      height: rem(84);
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      background-color: var(--bg-dark);
    }
  }

  &__name {
    width: 100%;
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-L-r);

    & span {
      color: var(--text-dark-primary);
    }
  }

  &__form {
    width: 100%;
    display: block;
  }

  &__connect {
    margin-bottom: rem(20);

    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-body-M-m);

    @include mediaBigDesktop {
      margin-bottom: big(20);
    }

    @include mediaLaptop {
      margin-bottom: rem(15);
    }

    @include mediaMobile {
      margin-bottom: rem(10);
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(10);
    }

    @include mediaTablet {
      flex-direction: row;
      justify-content: flex-end;
      gap: rem(15);
    }

    @include mediaMobile {
      flex-direction: column;
      gap: rem(10);
    }
  }

  &__social {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaLaptop {
      gap: rem(5);
    }

    @include mediaTablet {
      width: fit-content;
      gap: rem(10);
    }

    @include mediaMobile {
      width: 100%;
      gap: rem(5);
    }

    & .button {
      flex-grow: 0;
      flex-shrink: 0;
    }

    & .cta-sidebar {
      &__phone {
        --button-bg-color: var(--accent-primary-default);
        --button-bg-color-hover: var(--accent-primary-hover);

        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;

        @include mediaTablet {
          width: fit-content;
          flex-grow: 0;
          flex-shrink: 0;
        }

        @include mediaMobile {
          width: 100%;
          flex-grow: 1;
          flex-shrink: 1;
        }
      }

      &__whatsapp {
        --button-bg-color-hover: var(--button-icon-color);
        --button-icon-color: #60D669;
        --button-icon-color-hover: var(--text-light-primary);
      }

      &__tg {
        --button-bg-color-hover: var(--button-icon-color);
        --button-icon-color: #25A0E5;
        --button-icon-color-hover: var(--text-light-primary);
      }
    }
  }

  & .checkbox {
    --checkbox-gap: #{rem(15)};
    --checkbox-size: #{rem(30)};

    margin-top: rem(20);

    align-items: center;

    @include mediaBigDesktop {
      --checkbox-gap: #{big(15)};
      --checkbox-size: #{big(30)};

      margin-top: big(20);
    }

    @include mediaDesktop {
      --checkbox-size: #{rem(24)};
    }

    @include mediaLaptop {
      margin-top: rem(15);
    }

    &__input {
      &:checked {
        & ~ .checkbox__item {
          background: var(--bg-white);
          border-color: var(--stroke-light);

          &::before {
            background: var(--accent-secondary-default);
          }
        }
      }
    }

    &__item {
      --accent-primary-default: var(--accent-secondary-default);

      border-width: 1px;

      &::before {
        transition: background-color var(--animation-default);
      }
    }

    & .text {
      color: var(--text-dark-secondary);
      font: 400 rem(16)/1.55 var(--font-primary);

      @include mediaBigDesktop {
        font: 400 big(16)/1.55 var(--font-primary);
      }

      @include mediaDesktop {
        font: 500 rem(14)/1.55 var(--font-primary);
      }

      & a {
        color: var(--text-dark-primary);
        text-decoration: underline;

        @include hover {
          color: var(--accent-secondary-default);
        }
      }
    }
  }

  &__submit {
    margin-top: rem(30);

    width: 100%;

    @include mediaBigDesktop {
      margin-top: big(30);
    }

    @include mediaLaptop {
      margin-top: rem(20);
    }

    @include mediaTablet {
      width: fit-content;
    }

    @include mediaMobile {
      margin-top: rem(15);

      width: 100%;
    }
  }
}
