.text {
  margin: 0;
  
  padding: 0;

  //text-wrap: balance;

  &_body {
    &-xl {
      font: var(--font-body-XL-r);

      &_m {
        font: var(--font-body-XL-m);
      }
    }

    &-l {
      font: var(--font-body-L-r);

      &_sb {
        font: var(--font-body-L-sb);
      }

      &_m {
        font: var(--font-body-L-m);
      }
    }

    &-m {
      font: var(--font-body-M-r);

      &_m {
        font: var(--font-body-M-m);
      }
    }

    &-s {
      font: var(--font-body-S-r);

      &_m {
        font: var(--font-body-S-m);
      }
    }
  }

  &_card-title {
    font: var(--font-card-title);
    letter-spacing: calc(-1em / 100);

    &_l {
      font: var(--font-card-title-L);
    }

    &_m {
      font: var(--font-card-title-M);
      letter-spacing: calc(-1em / 100);
    }
  }

  &-accent {
    color: var(--accent-secondary-default);
  }
}

// h1 - h6
.h1,
.h1-main,
.h1-card,
.h2,
.h3,
.h4,
article h1,
article h2,
article h3,
article h4,
article h5,
article h6 {
  margin: 0;
  
  padding: 0;
  
  text-wrap: balance;
}



.h1 {
  font: var(--font-h1);

  &-main {
    font: var(--font-h1-main);
    letter-spacing: calc(-1em / 100);
  }

  &-card {
    font: var(--font-h1-card);
  }
}

.article h1 {
  font: var(--font-h1);
}

.h2,
.article h2 {
  font: var(--font-h2);
}

.h3,
.article h3 {
  font: var(--font-h3);
}

.h4,
.article h4,
.article h5,
.article h6 {
  font: var(--font-h4);
}
