.faq-block {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__item {
    position: relative;

    padding: rem(40) 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include mediaBigDesktop {
      padding: big(40) 0;
    }

    @include mediaLaptop {
      padding: rem(30) 0;
    }

    @include mediaTablet {
      padding: rem(25) 0;
    }

    @include mediaMobile {
      padding: rem(20) 0;
    }

    &::before {
      content: "";

      position: absolute;
      bottom: 0;left: 0;

      width: 100%;
      height: 1px;

      background-color: var(--stroke-light);

      @include mediaBigDesktop {
        height: big(1);
      }
    }

    &:first-child {
      padding-top: 0;
    }
  }

  &__head {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include hover {
      & .faq-block__title {
        color: var(--accent-primary-default);
      }
    }

    &::before {
      @include pseudo(15, false, true);

      @include mediaTablet {
        @include pseudo(10, true, false);
      }
    }

    & .icon {
      fill: var(--accent-primary-default);
    }
  }

  &__title {
    color: var(--text-dark-primary);
    font: var(--font-body-XL-m);

    transition: color var(--animation-default);

    @include mediaTablet {
      font: var(--font-body-L-m);
    }
  }

  &__hidden {
    width: 100%;
  }

  &__text[data-kordion-content] {
    padding-top: rem(20);
    width: 100%;

    color: var(--text-dark-secondary);
    font: var(--font-body-L-r);

    @include mediaBigDesktop {
      padding-top: big(20);
    }

    @include mediaLaptop {
      padding-top: rem(15);
    }

    @include mediaTablet {
      padding-top: rem(10);

      font: var(--font-body-M-r);
    }
  }
}
