.search-modal {
  --modal-accent-color: var(--accent-primary-default);
  --modal-background-color: var(--bg-white);
  --modal-close-icon-color: var(--accent-primary-default);
  --modal-close-icon-color-hover: var(--accent-secondary-default);
  --modal-close-icon-size: #{rem(34)};
  --modal-input-gap: #{rem(20)};
  --modal-input-height: #{rem(84)};
  --modal-padding: #{rem(70)};
  --modal-reset-bg-hover: var(--system-red);
  --modal-reset-fill: var(--text-dark-primary);
  --modal-reset-fill-hover: var(--text-light-primary);
  --modal-reset-icon-size: #{rem(18)};
  --modal-reset-size: #{rem(44)};
  --modal-result-border-radius: #{rem(25)};
  --modal-result-gap: #{rem(20)};
  --modal-result-padding: #{rem(35)};
  --modal-search-icon-size: #{rem(24)};
  --modal-text-color: var(--text-dark-primary);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;

  padding: var(--modal-padding) 0;
  width: 100%;
  height: fit-content;

  background-color: var(--modal-background-color);
  visibility: hidden;
  opacity: 0;

  transition: opacity var(--animation-default),
    visibility var(--animation-default);

  @include mediaBigDesktop {
    --modal-close-icon-size: #{big(34)};
    --modal-input-gap: #{big(20)};
    --modal-input-height: #{big(84)};
    --modal-padding: #{big(70)};
    --modal-reset-icon-size: #{big(18)};
    --modal-reset-size: #{big(44)};
    --modal-result-border-radius: #{big(25)};
    --modal-result-gap: #{big(20)};
    --modal-result-padding: #{big(35)};
    --modal-search-icon-size: #{big(24)};
  }

  @include mediaDesktop {
    --modal-close-icon-size: #{rem(24)};
    --modal-input-height: #{rem(70)};
    --modal-padding: #{rem(50)};
    --modal-reset-size: #{rem(40)};
    --modal-result-padding: #{rem(30)};
    --modal-search-icon-size: #{rem(20)};
  }

  @include mediaLaptop {
    --modal-close-icon-size: #{rem(20)};
    --modal-input-height: #{rem(58)};
    --modal-reset-icon-size: #{rem(16)};
    --modal-reset-size: #{rem(36)};
    --modal-result-border-radius: #{rem(20)};
    --modal-result-gap: #{rem(15)};
    --modal-result-padding: #{rem(25)};
    --modal-search-icon-size: #{rem(18)};
  }

  @include mediaTablet {
    --modal-close-icon-size: #{rem(18)};
    --modal-reset-icon-size: #{rem(14)};
  }

  @include mediaMobile {
    --modal-close-icon-size: #{rem(16)};
    --modal-input-gap: #{rem(12)};
    --modal-input-height: #{rem(44)};
    --modal-padding: #{rem(40)};
    --modal-reset-icon-size: #{rem(18)};
    --modal-reset-size: #{rem(26)};
    --modal-result-border-radius: #{rem(15)};
    --modal-result-gap: #{rem(10)};
    --modal-result-padding: #{rem(20)};
    --modal-search-icon-size: #{rem(16)};
  }

  &.search-modal_show {
    visibility: visible;
    opacity: 1;
  }

  & .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: var(--grid-row-gap);
    column-gap: var(--grid-column-gap);

    @include mediaTablet {
      display: flex;
      gap: 0;
    }
  }

  &__close {
    position: absolute;
    top: rem(30);
    right: rem(30);
    margin: 0;

    padding: 0;
    width: var(--modal-close-icon-size);
    height: var(--modal-close-icon-size);

    background-color: transparent;
    border: none;
    outline: none;

    cursor: pointer;
    user-select: none;

    @include mediaBigDesktop {
      top: big(30);
      right: big(30);
    }

    @include mediaTablet {
      top: rem(15);
      right: rem(15);
    }

    @include mediaMobile {
      top: rem(10);
      right: rem(10);
    }

    @include hover {
      & .icon {
        fill: var(--modal-close-icon-color-hover);
      }
    }

    &::before {
      @include pseudo(10, false, true);
    }

    & .icon {
      width: 100%;
      height: 100%;

      fill: var(--modal-close-icon-color);

      transition: fill var(--animation-default);
    }
  }

  &__wrapper {
    position: relative;
    grid-column: 2/6;

    width: 100%;
  }

  &__form {
    position: relative;

    padding: 0 rem(19) 0 rem(34);
    width: 100%;
    height: var(--modal-input-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    gap: var(--modal-input-gap);

    border: solid 1px var(--stroke-light);
    border-radius: calc(var(--modal-input-height) / 2);

    transition: border-color var(--animation-default);

    @include mediaBigDesktop {
      border-width: big(1);
    }

    & .button {
      flex-shrink: 0;
    }

    &:has(.search-modal__input:blank) {
      & .search-modal__reset {
        display: none;
      }
    }

    @media (any-hover: hover) {
      &:has(.search-modal__input:hover) {
        border-color: var(--accent-primary-default);
      }
    }

    &:has(.search-modal__input:focus) {
      border-color: var(--accent-primary-default);
    }
  }

  &__input {
    padding: 0;
    width: 100%;
    height: 100%;

    color: var(--text-dark-primary);
    font: var(--font-body-M-r);

    background-color: transparent;
    border: none;
    border-radius: 0;
    outline: none;

    &::placeholder {
      color: var(--text-dark-tertiary);
    }
  }

  &__submit {
    position: relative;
    margin: 0;

    padding: 0;
    width: var(--modal-search-icon-size);
    height: var(--modal-search-icon-size);
    flex-shrink: 0;

    background-color: transparent;
    border: none;

    cursor: pointer;

    @include hover {
      & .icon {
        fill: var(--accent-primary-default);
      }
    }

    &::before {
      @include pseudo(10, false, true);
    }

    & .icon {
      width: 100%;
      height: 100%;

      fill: var(--text-dark-primary);

      transition: fill var(--animation-default);
    }
  }

  &__reset.button {
    --button-bg-color-hover: var(--modal-reset-bg-hover);
    --button-icon-color: var(--modal-reset-fill);
    --button-icon-color-hover: var(--modal-reset-fill-hover);

    width: var(--modal-reset-size);
    height: var(--modal-reset-size);

    & .icon {
      width: var(--modal-reset-icon-size);
      height: var(--modal-reset-icon-size);
    }
  }

  &__result {
    position: absolute;
    top: calc(100% + #{rem(10)});
    left: 0;

    padding: var(--modal-result-padding);
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--modal-result-gap);

    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.04), 0px 5px 6px 0px rgba(0, 0, 0, 0.02), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    background-color: var(--modal-background-color);
    border-radius: var(--modal-result-border-radius);

    @include mediaBigDesktop {
      top: calc(100% + #{big(10)});

      box-shadow: 0px big(15) big(20) 0px rgba(0, 0, 0, 0.04), 0px big(5) big(6) 0px rgba(0, 0, 0, 0.02), 0px 0px big(1) 0px rgba(0, 0, 0, 0.04);
    }

    &:empty {
      display: none;
    }
  }

  &__item {
    position: relative;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--modal-input-gap);

    color: var(--modal-text-color);
    font: var(--font-body-M-r);
    text-decoration: none;

    transition: color var(--animation-default);

    @include hover {
      color: var(--accent-primary-default);

      &::before {
        background-color: var(--accent-primary-default);
      }
    }

    &::before {
      content: "";

      width: var(--modal-search-icon-size);
      height: var(--modal-search-icon-size);
      flex-shrink: 0;

      background-color: var(--text-dark-tertiary);

      transition: background-color var(--animation-default);

      mask-image: url(../../dev/sprite/search.svg);
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
    }

    &::after {
      @include pseudo(10, false, true) {
        & {
          @include mediaLaptop {
            @include pseudo(7.5, true, false);
          }

          @include mediaMobile {
            @include pseudo(5, true, false);
          }
        }
      }
    }
  }
}
